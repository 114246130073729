import { GridListTile } from '@material-ui/core';
import React, { useCallback, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useStore } from '../../../contexts/StoreContext';

import {
    MOVIE_TYPE_FAVORITED,
    VideoTypeTabContext,
} from '../../../contexts/VideoTypeTabContext';
import {
    getMovieComparer,
    getMoviePredicate,
    getRefineWithYardRange,
} from '../../../utils/MovieSorting';
import { MovieDisplay } from '../../../utils/Video';
import MovieThumbnail from '../display/MovieThumbnail';
import VideoGridLayout from '../layout/VideoGridLayout';
import { MOVIE_TYPE_LESSON } from '../../const/MovieTypeProperties';
import useSelectMovieDisplayHandler from '../../../hooks/useSelectMovieDisplayHandler';
import { Movie } from '../../../domains/resource/movie';

const StyledGridListTile = styled(GridListTile)`
    height: 150px !important;
    width: 19% !important;
    margin-right: 5px;
`;

type Props = {
    customerId: string;
};

const FavoritedMovies: React.FC<Props> = ({ customerId }) => {
    const { movieType, SortClub, YardRanges, SortOrder } = useContext(VideoTypeTabContext);

    const { tempoStore } = useStore();
    const { favoriteMovies } = tempoStore;
    const sortedMovies = useMemo(() => {
        if (movieType !== MOVIE_TYPE_FAVORITED) {
            return [];
        }
        return favoriteMovies
            .map((favorite) => favorite.movie)
            .filter((movie) => {
                return (
                    movie.movie_type === MOVIE_TYPE_LESSON &&
                    movie.customer_id_or_staff_id === customerId
                );
            })
            .filter(getMoviePredicate(SortClub))
            .filter(getRefineWithYardRange(YardRanges))
            .sort(getMovieComparer(SortOrder));
    }, [customerId, SortClub, YardRanges, SortOrder, favoriteMovies, movieType]);

    const { onSelected } = useSelectMovieDisplayHandler();
    const handleClick = useCallback((data: MovieDisplay) => {
        onSelected(Movie.fromEntity(data));
    }, [onSelected]);

    return (
        <VideoGridLayout isVisible={movieType === MOVIE_TYPE_FAVORITED}>
            {sortedMovies.concat().map((video) => (
                <StyledGridListTile key={video.id}>
                    <MovieThumbnail video={video} onClick={handleClick} />
                </StyledGridListTile>
            ))}
        </VideoGridLayout>
    );
};

export default FavoritedMovies;
