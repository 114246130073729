import React, { useCallback } from 'react';
import { Card, Container } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';

import { PlayingType } from './Video';
import PlayingVideoSingle from './PlayingVideoSingle';
import PlayingVideoDouble from './PlayingVideoDouble';
import { useAsync } from 'react-use';
import Api from '../../utils/Api';
import { isNotUndefined } from '../../utils/utilityFuncs';
import { MovieDisplay } from '../../utils/Video';

const useStyles = makeStyles(() =>
    createStyles({
        lowerBox: {
            display: 'flex',
            justifyContent: 'space-around',
            padding: 10,
        },
        higherBox: {
            display: 'flex',
            justifyContent: 'center',
            padding: '10px 0',
        },
        videoContainer: {
            padding: '16px 16px',
        },
    }),
);

export interface VideoMovie {
    id: number,
    type: 'movie';
    filepath: string;
    filename: string;
    customerIdOrStaffId: string;
    movieCreatedAt: string;
    toolImageFilename: string;
    toolImageFilePath: string;
}

export const fromMovieDisplayToVideoMovie: (data: MovieDisplay) => VideoMovie = ({
    id,
    src,
    filename,
    customer_id_or_staff_id,
    created_at,
    tool_image_filename,
    tool_image_filepath
}): VideoMovie => {
    return {
        id: Number(id),
        type: 'movie',
        filepath: src ?? '',
        filename: filename,
        customerIdOrStaffId: customer_id_or_staff_id,
        movieCreatedAt: created_at,
        toolImageFilename: tool_image_filename,
        toolImageFilePath: tool_image_filepath,
    };
}

export interface VideoBlob {
    id?: number;
    type: 'blob';
    videoSrc: string;
    blob: Blob;
    toolImage: File | undefined;
}

export interface LocationState {
    video: (VideoMovie | VideoBlob)[];
    customerId?: string;
}

interface Props {
    type: PlayingType;
}

const height = 700;
const widthForSingle = 550;
const widthForDouble = 350;
const scaleRatio = widthForDouble / widthForSingle; //ビデオのwidthが変わるとその再生画面として表示される縦幅もズレるためその調整のために用意

const PlayingVideoCard = observer((props: Props): React.ReactElement => {
    const { type } = props;
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation<LocationState & { from: string }>();

    const { customerId, from } = location.state;
    const urlsState = useAsync(async () => {
        const urls = await Promise.all(
            location.state.video.map(async (video) => {
                if (video.type === 'movie') {
                    const { presignedUrl } =
                        await Api.getS3SignedUrlForDownloadMovie(
                            video.filepath,
                        );
                    video.filepath = presignedUrl;
                    if (video.toolImageFilePath) {
                        const { presignedUrl: presignedUrlForTool } =
                            await Api.getS3SignedUrlForDownloadToolImage(
                                video.toolImageFilePath,
                            );
                        video.toolImageFilePath = presignedUrlForTool;
                    }
                    return video;
                }
                if (video.type === 'blob') {
                    return video;
                }
                return undefined;
            }),
        );
        return urls.filter(isNotUndefined);
    }, [location.state.video]);
    const urls = urlsState.value;

    const onClickToVideoList = useCallback(() => {
        history.push({
            pathname: '/video/list',
            state: {
                from,
                customerId,
            },
        });
    }, [history, customerId, from]);

    if (urls === undefined) {
        return <></>;
    }

    return (
        <div>
            <Container className={classes.videoContainer}>
                <Card>
                    {type == PlayingType.single ? (
                        <PlayingVideoSingle
                            videoSrc={urls[0]}
                            height={height}
                            width={widthForSingle}
                            customerId={customerId}
                            onClickToVideoList={onClickToVideoList}
                        />
                    ) : type == PlayingType.double ? (
                        <PlayingVideoDouble
                            videoSrcLeft={urls[0]}
                            videoSrcRight={urls[1]}
                            height={height}
                            width={widthForDouble}
                            scaleRatio={scaleRatio}
                            customerId={customerId}
                            onClickToVideoList={onClickToVideoList}
                        />
                    ) : (
                        // ) : type == PlayingType.transparent ? (
                        //     <PlayingVideoTransParent />
                        []
                    )}
                </Card>
            </Container>
        </div>
    );
});

export default PlayingVideoCard;
