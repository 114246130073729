import React, { useMemo } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import { format } from 'date-fns';
import { useStore } from '../../../contexts/StoreContext';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    font-weight: bold;
    width: 100%;
`;

const PlayerName = styled.label`
    display: block;
    font-size: 21px;
    color: ${colors.black};
    font-weight: bold;
`;

const LessonInfomation = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-conten: flex-end;
`;

const LessonInfomationColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
`;

const CreatedAt = styled.div`
    text-align: right;
`;

const InlineList = styled.ul`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    > li {
        display: block;
        box-sizing: border-box;
        padding-right: 15px;
        line-height: 1.5;
        &:last-child {
            padding-right: 0;
        }
        &:before {
            content: '●';
        }
    }
`;

const MovieIndexTitle = styled.span`
    font-weight: bold;
    font-size: 18px;
    color: ${colors.footerIconSelected};
    text-align: right;
    min-height: 28px;
`;

const MovieIndex = styled.span`
    margin: 0;
    padding: 0;
    margin-left: 20px;
    color: ${colors.footerIconSelected};
    font-size: 45px;
    font-weight: bold;
    line-height: 1;
`;


type Props = {
    ownerId: string
    createdAt?: string
    club?: string | null
    yard?: string | null
    movieSequence?: number
}

const Header: React.FC<Props> = ({
    ownerId,
    createdAt,
    club,
    yard,
    movieSequence
}) => {
    const { tempoStore, lessonStore } = useStore()
    const userName = useMemo(() => {
        return (
            tempoStore.resolveUserName(Number(ownerId)) ||
            lessonStore.resolveCustomerName(Number(ownerId)) ||
            []
        );
    }, [tempoStore, lessonStore, ownerId]);

    const formattedCreatedAt = useMemo(() => {
        const date = createdAt ? new Date(createdAt) : new Date();
        return format(date, 'yyyy/MM/dd');
    }, [createdAt])

    const nullishedClub = useMemo(() => {
        return club === null ? undefined : club;
    }, [club]);

    const nullishedYard = useMemo(() => {
        return yard === null ? undefined : yard;
    }, [yard]);

    return (
        <HeaderContainer>
            <PlayerName className='userName'>
                {userName.join(' ')}
            </PlayerName>
            <LessonInfomation>
                <LessonInfomationColumn>
                    <CreatedAt>
                        {formattedCreatedAt}
                    </CreatedAt>
                    <InlineList>
                        <li>{nullishedClub}</li>
                        <li>{nullishedYard}y</li>
                    </InlineList>
                </LessonInfomationColumn>
                <MovieIndex>{movieSequence}</MovieIndex>
            </LessonInfomation>
        </HeaderContainer>
    )
}

export default Header;
