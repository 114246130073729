import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import HeaderIcon from '../../assets/note.svg';
import { MovieDisplay } from '../../utils/Video';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import { fromMovieDisplayToVideoMovie, VideoMovie } from '../video/PlayingVideoCard';
import VideoComposer from '../video/VideoComposer';
import PlayerHeaderNavigation from './navigation/PlayerHeaderNavigation';
import { MultiplePlayMovieContext } from '../../contexts/MultiplePlayMovieContext';
import { Movie } from '../../domains/resource/movie';

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: ${colors.gray};
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: ${colors.white};
    .userName {
        color: ${colors.white};
    }
`;

type LocationProps = {
    movie: MovieDisplay;
    movieIndex: number;
};

const PlaySinglePage: React.FC = () => {
    const location = useLocation<LocationProps>();
    const { state } = location;
    const [saveCounter, setSaveCounter] = useState<number>(0);
    const { movie, movieIndex } = state;
    const videoMovie: VideoMovie = fromMovieDisplayToVideoMovie(movie);

    const { setMovies, setMultiplePlayMode } = useContext(MultiplePlayMovieContext)
    useEffect(() => {
        setMovies([Movie.fromEntity(movie)]);
        setMultiplePlayMode(true);
    }, [movie, setMovies, setMultiplePlayMode]);

    return (
        <Page title='レッスンノート' headerIcon={HeaderIcon ?? ''}>
            <ContentContainer>
                <PlayerHeaderNavigation
                    customerId={movie.customer_id_or_staff_id}
                >
                    過去のスイング
                </PlayerHeaderNavigation>
                <VideoContainer>
                    <VideoComposer
                        ownerId={movie.customer_id_or_staff_id}
                        toolImageFilePath={movie.tool_image_filepath}
                        movieSequence={movieIndex}
                        createdAt={movie.created_at}
                        club={movie.club}
                        yard={movie.yards}
                        isBestSwing={movie.desirable}
                        videoSrc={videoMovie}
                        height={601}
                        width={416}
                        saveCounter={saveCounter}
                        setSaveCounter={setSaveCounter}
                    />
                </VideoContainer>
            </ContentContainer>
        </Page>
    );
};

export default observer(PlaySinglePage);
