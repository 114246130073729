import { RefObject, useEffect } from "react";
import Api from "../utils/Api";

export default function useRenderToolImage(
    canvasRef: RefObject<HTMLCanvasElement>,
    videoType: string,
    toolImageFilePath?: string,
): void {

    const getImageUrl = async (
        url: string,
        videoType: string,
    ): Promise<string> => {
        if (videoType === 'movie') {
            return (await Api.getS3SignedUrlForDownloadToolImage(url))
                .presignedUrl;
        }
        return url;
    };

    useEffect(() => {
        const renderFunc = async (): Promise<void> => {
            const { current } = canvasRef;
            if (toolImageFilePath && current) {
                const url = await getImageUrl(toolImageFilePath, videoType);
                const ctx = current.getContext('2d');
                if (ctx) {
                    ctx.clearRect(0, 0, current.width, current.height);
                    const img = new Image();
                    img.crossOrigin = 'anonymous';
                    img.onload = () => {
                        ctx.drawImage(img, 0, 0, current.width, current.height);
                    };
                    img.src = url;
                }
            }
        };

        renderFunc()
            .catch((error) => {
                console.error(error);
            });
    }, [toolImageFilePath, videoType, canvasRef]);
}
