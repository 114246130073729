import {
    Button,
    createStyles,
    makeStyles,
    Slider,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, {
    ForwardedRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';

import { useStore } from '../../../contexts/StoreContext';
import useCustomerBestSwingToggler from '../../../hooks/useCustomerBestSwingToggler';
import useEditorAngle from '../../../hooks/useEditorAngle';
import useEditorCanvas, {
    LineColor,
    ToolType,
} from '../../../hooks/useEditorCanvas';
import useEditorCircle from '../../../hooks/useEditorCircle';
import useEditorGuidStamp from '../../../hooks/useEditorGuidStamp';
import useEditorLine from '../../../hooks/useEditorLine';
import useEditorRectangle from '../../../hooks/useEditorRectangle';
import useRenderToolImage from '../../../hooks/useRenderToolImage';
import useVideoJS from '../../../hooks/useVideoJs';
import RecordingStore, { SavedToolImageMap } from '../../../store/RecordingStore';
import Api from '../../../utils/Api';
import base64ToFile from '../../../utils/base64ToFile';
import BestSwingButton from '../../base/surface/BestSwingIcon';
import { colors as ThemeColors } from '../../const/Styles';
import MovieEditorToolLeft from '../../swing/display/MovieEditorToolLeft';
import MovieEditorToolRight from '../../swing/display/MovieEditorToolRight';
import { VideoBlob, VideoMovie } from '../PlayingVideoCard';
import AngleSVG from '../SVG/AngleSVG';
import CircleSVG from '../SVG/CircleSVG';
import ForwardTiltGuidSVG from '../SVG/ForwardTiltGuidSVG';
import FrontGuidSVG from '../SVG/FrontGuidSVG';
import LineSVG from '../SVG/LineSVG';
import RectangleSVG from '../SVG/RectangleSVG';
import SwingPlaneSVG from '../SVG/SwingPlaneSVG';
import Footer from './Footer';
import Header from './Header';
import PlayController from './PlayControler';

const useStyles = makeStyles(() =>
    createStyles({
        video: {
            position: 'absolute',
            '& > .vjs-tech': {
                objectFit: 'fill',
            },
        },
        canvas: {
            border: '1px solid gray',
            backgroundColor: 'white',
            position: 'absolute',
        },
        thumbnail: {
            width: 55,
            height: 55,
        },
        imgButton: {
            padding: 0,
            minWidth: 0,
        },
        toggleButton: {
            border: 'None',
            borderRadius: 'None',
        },
    }),
);

const Container = styled.div`
    display: grid;
    grid-template-rows: 1fr max-content max-content;
    width: 100%;
    height: 100%;
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
    align-items: center;
`

const PlayControlContainer = styled(ContentContainer)`
    gap: 4px;
`

const VideoContainer = styled.div`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    box-sizing: border-box;
`;

const ThumbnailContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    min-height: 55px;
    margin-bottom: 15px;
`;

const ToolContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;

const StyledBestSwingButton = styled(BestSwingButton)`
    position: absolute;
    top: 0;
    left: calc(100% + 12px);
`;

const StyledSeekbar = styled(Slider)`
    color: ${ThemeColors.footerIconSelected};
    padding: 8px 0;

    .MuiSlider-thumb {
        width: 24px;
        height: 24px;
        margin-top: -10px;
    }
`;

interface Props {
    videoSrc: VideoMovie | VideoBlob;
    toolImageFilePath?: string
    ownerId: string,
    createdAt?: string
    club?: string | null
    yard?: string | null
    movieSequence?: number
    isBestSwing?: boolean
    saveCounter: number;
    setSaveCounter: React.Dispatch<React.SetStateAction<number>>;
    height: number;
    width: number;
    onSaveCallback?: (toolImageFileName: string) => void;
    isBackgroundDark?: boolean
}

/**
 * MEMO: 再生と同時編集するVideoEditor
 *  - 要件上コーチスイングまでは変えないので分離している
 */
const VideoComposer = observer(
    (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const {
            videoSrc,
            saveCounter,
            setSaveCounter,
            height,
            width,
            onSaveCallback,
        } = props;
        const classes = useStyles();
        const src = useMemo(
            () =>
                videoSrc.type === 'movie'
                    ? videoSrc.filepath
                    : videoSrc.videoSrc,
            [videoSrc],
        );
        const { Video, player, actions, thumbnails } = useVideoJS({
            sources: [{ src: src, type: 'video/mp4' }],
            height: height,
            width: width,
            responsive: true,
        });

        const [canvasValues, canvasActions] = useEditorCanvas(videoSrc);
        const [lineValues, lineActions] = useEditorLine();
        const [angleValues, angleActions] = useEditorAngle();
        const [planeValues, planeActions] = useEditorAngle();
        const [rectValues, rectActions] = useEditorRectangle();
        const [circleValues, circleActions] = useEditorCircle();
        const [frontStampValues, frontStampActions] = useEditorGuidStamp();
        const [forwardStampValues, forwardStampActions] = useEditorGuidStamp();

        const onClickDeleteAll = useCallback(() => {
            const canvas = canvasValues.canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                }
            }
            lineActions.reset();
            angleActions.reset();
            planeActions.reset();
            rectActions.reset();
            circleActions.reset();
            frontStampActions.reset();
            forwardStampActions.reset();
        }, [
            angleActions,
            canvasValues.canvasRef,
            circleActions,
            forwardStampActions,
            frontStampActions,
            lineActions,
            planeActions,
            rectActions,
        ]);

        const [trigger1, setTrigger1] = useState(false);
        const [trigger2, setTrigger2] = useState(false);
        const [trigger3, setTrigger3] = useState(false);
        const [trigger4, setTrigger4] = useState(false);
        const [trigger5, setTrigger5] = useState(false);
        const [trigger6, setTrigger6] = useState(false);
        const [trigger7, setTrigger7] = useState(false);
        const svgLineRef = useRef<SVGSVGElement>(null);
        const svgAngleRef = useRef<SVGSVGElement>(null);
        const svgPlaneRef = useRef<SVGSVGElement>(null);
        const svgRectangleRef = useRef<SVGSVGElement>(null);
        const svgCircleRef = useRef<SVGSVGElement>(null);
        const svgFrontStampRef = useRef<SVGSVGElement>(null);
        const svgForwardStampRef = useRef<SVGSVGElement>(null);

        const pasteSvgToCanvas = useCallback(
            (
                svgRef: React.RefObject<SVGSVGElement>,
                ctx: CanvasRenderingContext2D,
                setTriggerCallback: (bool: boolean) => void,
            ) => {
                const img = new Image();
                if (svgRef.current) {
                    const svgData = new XMLSerializer().serializeToString(
                        svgRef.current,
                    );
                    img.src =
                        'data:image/svg+xml;charset=utf-8;base64,' +
                        btoa(svgData);
                }
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    setTriggerCallback(true);
                };
            },
            [],
        );
        const onClickSaveAsImage = useCallback(() => {
            const canvas = canvasValues.canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    pasteSvgToCanvas(svgLineRef, ctx, setTrigger1);
                    pasteSvgToCanvas(svgAngleRef, ctx, setTrigger2);
                    pasteSvgToCanvas(svgPlaneRef, ctx, setTrigger3);
                    pasteSvgToCanvas(svgRectangleRef, ctx, setTrigger4);
                    pasteSvgToCanvas(svgCircleRef, ctx, setTrigger5);
                    pasteSvgToCanvas(svgFrontStampRef, ctx, setTrigger6);
                    pasteSvgToCanvas(svgForwardStampRef, ctx, setTrigger7);
                }
            }
        }, [canvasValues.canvasRef, pasteSvgToCanvas]);

        useEffect(() => {
            if (
                trigger1 &&
                trigger2 &&
                trigger3 &&
                trigger4 &&
                trigger5 &&
                trigger6 &&
                trigger7
            ) {
                const base64 = canvasValues.canvasRef.current
                    ?.toDataURL('image/png')
                    .split(',')[1];
                if (base64) {
                    const imgFile = base64ToFile(base64, 'test');
                    if (imgFile) {
                        if (videoSrc.type === 'movie') {
                            void (async () => {
                                const result = await Api.updateToolImage(
                                    videoSrc.id,
                                    videoSrc.customerIdOrStaffId,
                                    videoSrc.movieCreatedAt,
                                    videoSrc.filename,
                                );
                                const presignedUrl =
                                    await Api.getS3SignedUrlForUploadToolImage(
                                        result.tool_image_filename,
                                    );
                                await Api.uploadToS3(
                                    presignedUrl.presignedUrl,
                                    imgFile,
                                );
                                if (onSaveCallback) {
                                    onSaveCallback(result.tool_image_filename);
                                }
                            })();
                            RecordingStore.saveToolImage(videoSrc, imgFile);
                            canvasActions.drawDefaultImage(videoSrc, imgFile);
                        } else {
                            RecordingStore.saveToolImage(videoSrc, imgFile);
                        }
                        window.alert('編集内容を保存しました');
                        setSaveCounter(saveCounter + 1);
                    }
                }
                angleActions.reset();
                planeActions.reset();
                setTrigger1(false);
                setTrigger2(false);
                setTrigger3(false);
                setTrigger4(false);
                setTrigger5(false);
                setTrigger6(false);
                setTrigger7(false);
            }
        }, [
            angleActions,
            canvasActions,
            canvasValues.canvasRef,
            planeActions,
            saveCounter,
            setSaveCounter,
            trigger1,
            trigger2,
            trigger3,
            trigger4,
            trigger5,
            trigger6,
            trigger7,
            videoSrc,
            onSaveCallback,
        ]);

        const [playing, setPlaying] = useState<boolean>(false);
        useEffect(() => {
            if (player) {
                player.on('ended', () => {
                    setPlaying(false);
                });
            }
        }, [player]);
        const startVideo = useCallback(() => {
            if (player?.paused()) {
                player?.play();
                setPlaying(true);
            } else {
                player?.pause();
                setPlaying(false);
            }
        }, [player]);

        const [currentSeekbarRatio, setCurrentSeekbarRatio] = useState(0);
        useEffect(() => {
            const intervalId = setInterval(() => {
                if (!player) {
                    return;
                }

                setCurrentSeekbarRatio(Math.round(player.currentTime() / player.duration() * 100));
            }, 10);
            return () => {
                clearInterval(intervalId);
            };
        }, [player, setCurrentSeekbarRatio]);

        const onSeekbarChange = useCallback((event, seekbarValue) => {
            actions.skipByRatio(seekbarValue);
        }, [actions],);

        const [toolType, setToolType] = React.useState<ToolType>(
            ToolType.straightLine,
        );
        const handleToolType = (newToolType: ToolType) => {
            setToolType(newToolType);
            canvasActions.setToolType(newToolType);
        };

        const [lineColor, setLineColor] = React.useState<LineColor>(
            LineColor.red,
        );
        const handleLineColor = (newLineColor: LineColor) => {
            setLineColor(newLineColor);
            canvasActions.setLineColor(newLineColor);
        };

        const [toolVisible, setToolVisible] = useState(true);
        useRenderToolImage(
            canvasValues.canvasRef,
            videoSrc.type,
            props.toolImageFilePath,
        );

        const [isActive, setIsActive] = useState(props.isBestSwing || false)
        const bestSwingToggler = useCustomerBestSwingToggler(
            props.ownerId,
            videoSrc.id || 0,
            videoSrc.type === 'blob'
        );

        const handleClickBestSwingButton = useCallback(async () => {
            setIsActive(await bestSwingToggler());
        }, [bestSwingToggler]);

        const { recordingStore } = useStore()
        const toolMapImage = useMemo<SavedToolImageMap | undefined>(() => {
            // 撮影済みのものは編集させない
            if (videoSrc.type === 'movie') {
                return undefined;
            }

            const target = recordingStore.getRegisteredVideo(props.ownerId)?.find(({ video }) => video === videoSrc.blob);

            if (typeof target === 'undefined') {
                return target;
            }

            if (!target.video) {
                return undefined;
            }

            return {
                id: target.id,
                video: target.video,
                videoUrl: videoSrc.videoSrc,
                toolImage: videoSrc.toolImage,
            };
        }, [recordingStore, props.ownerId, videoSrc]);

        const handleClickVideo = async () => {
            await actions.togglePlay();
        }

        return (
            <Container ref={ref} tabIndex={-1}>
                <ContentContainer>
                    <Header
                        ownerId={props.ownerId}
                        movieSequence={props.movieSequence}
                        createdAt={props.createdAt}
                        club={props.club}
                        yard={props.yard}
                    />
                    <VideoContainer>
                        <Video
                             playsInline
                             className={`${classes.video} video-js vjs-big-play-centered`}
                             onClick={handleClickVideo}
                         />
                        <canvas
                            style={{ zIndex: 1, touchAction: 'none' }}
                            ref={canvasValues.canvasRef}
                            id='canvas'
                            height={`${height}px`}
                            width={`${width}px`}
                            onPointerDown={(e) =>
                                canvasActions.startDrawing(
                                    e.nativeEvent.offsetX,
                                    e.nativeEvent.offsetY,
                                )
                            }
                            onPointerUp={(e) =>
                                canvasActions.endDrawing(
                                    e.nativeEvent.offsetX,
                                    e.nativeEvent.offsetY,
                                )
                            }
                            onPointerLeave={() => canvasActions.endDrawing()}
                            onPointerMove={(e) =>
                                canvasActions.draw(
                                    e.nativeEvent.offsetX,
                                    e.nativeEvent.offsetY,
                                )
                            }
                        />
                        <LineSVG
                            svgLineRef={svgLineRef}
                            canvasValues={canvasValues}
                            lineValues={lineValues}
                            lineActions={lineActions}
                            height={height}
                            width={width}
                        />
                        <AngleSVG
                            svgAngleRef={svgAngleRef}
                            canvasValues={canvasValues}
                            angleValues={angleValues}
                            angleActions={angleActions}
                            height={height}
                            width={width}
                        />
                        <SwingPlaneSVG
                            svgPlaneRef={svgPlaneRef}
                            canvasValues={canvasValues}
                            planeValues={planeValues}
                            planeActions={planeActions}
                            height={height}
                            width={width}
                        />
                        <RectangleSVG
                            svgRectangleRef={svgRectangleRef}
                            canvasValues={canvasValues}
                            rectValues={rectValues}
                            rectActions={rectActions}
                            height={height}
                            width={width}
                        />
                        <CircleSVG
                            svgCircleRef={svgCircleRef}
                            canvasValues={canvasValues}
                            circleValues={circleValues}
                            circleActions={circleActions}
                            height={height}
                            width={width}
                        />
                        <FrontGuidSVG
                            svgStampRef={svgFrontStampRef}
                            canvasValues={canvasValues}
                            stampValues={frontStampValues}
                            stampActions={frontStampActions}
                            height={height}
                            width={width}
                        />
                        <ForwardTiltGuidSVG
                            svgStampRef={svgForwardStampRef}
                            canvasValues={canvasValues}
                            stampValues={forwardStampValues}
                            stampActions={forwardStampActions}
                            height={height}
                            width={width}
                        />
                        <MovieEditorToolRight
                            toolVisible={toolVisible}
                            selected={toolType}
                            selectedColor={lineColor}
                            onClick={handleToolType}
                            onLineColorChange={handleLineColor}
                        />
                        <MovieEditorToolLeft
                            onClickDeleteAll={onClickDeleteAll}
                            onClickVisible={setToolVisible}
                        />
                        <StyledBestSwingButton
                            onClick={handleClickBestSwingButton}
                            isActive={isActive}
                        />
                    </VideoContainer>
                </ContentContainer>
                <PlayControlContainer>
                    <ThumbnailContainer>
                        {thumbnails?.map(([thumbnail, currentTime], index) => {
                            return (
                                <Button
                                    key={index}
                                    onClick={() => {
                                        actions.skip(currentTime);
                                    }}
                                    className={classes.imgButton}
                                >
                                    <img
                                        src={thumbnail}
                                        className={classes.thumbnail}
                                    />
                                </Button>
                            );
                        })}
                    </ThumbnailContainer>
                    <ToolContainer>
                        <StyledSeekbar
                            min={0}
                            max={100}
                            value={currentSeekbarRatio}
                            onChange={onSeekbarChange}
                        />
                    </ToolContainer>
                    <PlayController
                        isBackgroundDark={props.isBackgroundDark}
                        actions={actions}
                        onStart={startVideo}
                        isPlaying={playing}
                        useFullScreen={true}
                        seekbarAction={{
                            duration: player?.duration(),
                            onChangeSlider: undefined,
                        }}
                    />
                </PlayControlContainer>
                <Footer
                    toolmap={toolMapImage}
                    isRecording={videoSrc.type === 'blob'}
                    onClickSave={onClickSaveAsImage}
                />
            </Container>
        );
    },
    { forwardRef: true },
);

export default VideoComposer;
