import { IconButton } from '@material-ui/core';
import PlayArrowSharp from '@material-ui/icons/PlayArrowSharp';
import React, { ChangeEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

const PlaybackRateValues = [1.5, 1, 0.5, 0.1, 0.0625];
const DefaultPlaybackRate = 1;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
    position: relative;
    right: -30px;
`;

const Select = styled.select<{ $bgDark: boolean }>`
    outline: none;
    border: solid 2px ${({ $bgDark }) => $bgDark ? colors.white : colors.gray};
    background-color: ${({ $bgDark }) => $bgDark ? colors.gray : colors.white};
    color: ${({ $bgDark }) => $bgDark ? colors.white : colors.black};
    box-sizing: border-box;
    padding: 2px 8px;
    text-align: center;
    border-radius: 999999px;
`;

const StyledIconButton = styled(IconButton)`
    padding: 0;
`;

type IconProps = {
    rotate: number;
    $bgDark: boolean;
};

const StyledIcon = styled(PlayArrowSharp) <IconProps>`
    transform: rotate(${({ rotate }) => rotate}deg);
    display: flex;
    color: ${({ $bgDark }) => $bgDark ? colors.white : colors.gray};
    height: 30px;
    width: 30px;
`;


type Props = {
    isBackgroundDark?: boolean
    onChange?: (value: number) => void;
};

const PlaybackRateSelector: React.FC<Props> = ({
    isBackgroundDark = true,
    onChange
}) => {
    const [playbackRate, setPlaybackRate] = useState(DefaultPlaybackRate);

    const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const val = Number(evt.currentTarget.value);
        setPlaybackRate(val);
        if (onChange) {
            onChange(val);
        }
    };

    const handleIncrease = useCallback(() => {
        const index = PlaybackRateValues.findIndex((p) => p === playbackRate);
        if (typeof index === 'undefined' || !onChange) {
            return;
        }
        const next = PlaybackRateValues[index - 1];
        if (next) {
            setPlaybackRate(next);
            onChange(next);
        }
    }, [onChange, playbackRate]);

    const handleDecrease = useCallback(() => {
        const index = PlaybackRateValues.findIndex((p) => p === playbackRate);
        if (typeof index === 'undefined' || !onChange) {
            return;
        }
        const next = PlaybackRateValues[index + 1];
        if (typeof next === 'number') {
            setPlaybackRate(next);
            onChange(next);
        }
    }, [onChange, playbackRate]);

    return (
        <Container>
            <StyledIconButton onClick={handleIncrease}>
                <StyledIcon rotate={-90} $bgDark={isBackgroundDark} />
            </StyledIconButton>
            <Select $bgDark={isBackgroundDark} value={playbackRate} onChange={handleChange}>
                {PlaybackRateValues.map((value, index) => (
                    <option key={index} value={value}>
                        x{value}
                    </option>
                ))}
            </Select>
            <StyledIconButton onClick={handleDecrease}>
                <StyledIcon rotate={90} $bgDark={isBackgroundDark} />
            </StyledIconButton>
        </Container>
    );
};

export default PlaybackRateSelector;
