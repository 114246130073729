import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import HeaderIcon from '../../assets/windows.svg';
import { Movie } from '../../domains/resource/movie';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import { VideoMovie } from '../video/PlayingVideoCard';
import VideoComposer from '../video/VideoComposer';
import PlayerHeaderNavigation from './navigation/PlayerHeaderNavigation';

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: ${colors.white};
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
`;

const PlaySinglePage: React.FC = () => {
    const location = useLocation<Movie.PersistedResource>();
    const { state } = location;
    const [saveCounter, setSaveCounter] = useState<number>(0);
    const videoMovie: VideoMovie = {
        id: state.id,
        type: 'movie',
        filepath: state.video.signedUrl,
        filename: state.video.fileName,
        customerIdOrStaffId: state.ownerId,
        movieCreatedAt: state.createdAt || '',
        toolImageFilename: state.toolImage.fileName,
        toolImageFilePath: state.toolImage.filePath,
    };

    return (
        <Page title='スイング一覧' headerIcon={HeaderIcon ?? ''}>
            <ContentContainer>
                <PlayerHeaderNavigation>スイング再生</PlayerHeaderNavigation>
                <VideoContainer>
                    <VideoComposer
                        isBackgroundDark={false}
                        ownerId={state.ownerId}
                        createdAt={state.createdAt}
                        club={state.club}
                        yard={state.yard}
                        isBestSwing={state.isBestSwing}
                        videoSrc={videoMovie}
                        height={601}
                        width={416}
                        toolImageFilePath={state.toolImage.filePath}
                        saveCounter={saveCounter}
                        setSaveCounter={setSaveCounter}
                    />
                </VideoContainer>
            </ContentContainer>
        </Page>
    );
};

export default observer(PlaySinglePage);
