import { Button } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { SwingHistory } from '../../@types/location';
import { Routes } from '../../const/Routes';
import { border, colors } from '../../const/Styles';
import { LocationState as LocationStateForPlayingVideoCard } from '../../video/PlayingVideoCard';

const Container = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0;
    width: 100%;
    color: ${colors.footerIconSelected};
    border-top: solid 5px ${border.gray};
`;

const BackButton = styled(Button)`
    background-color: ${colors.footerIconSelected};
    color: ${colors.black};
    font-weight: bold;
    padding: 4px 160px;
    font-size: 18px;
    border-radius: 4px;
`;

const ReturnPerviousButton: React.FC = () => {
    const history = useHistory<
        LocationStateForPlayingVideoCard & { from?: string }
    >();

    const location = useLocation<SwingHistory>();

    const handleClick = () => {
        history.goBack();
    };

    const buttonText = useMemo(() => {
        const { from } = location.state;
        switch (from) {
            case Routes.LIBRARY_DETAIL:
                // FIXME: historyAPIで未永続化動画再生ページに戻ると動作しない件が解決するまでは前のページに戻るとしておく
                // return 'ライブラリー詳細画面';
                // fall through
            case Routes.LESSON_START:
                // return 'レッスン画面';
                // fall through
            case Routes.LESSON_PAST_SWING:
                // return 'レッスン画面';
                // fall through
            default:
                return '前のページ';
                // return 'その他のページ';
        }
    }, [location.state]);

    return (
        <Container>
            <BackButton onClick={handleClick}>
                {/* 現行のシステムではお客様一覧にもどるかレッスン画面に戻るかの2択なのでいったんURLでボタン名を変更(その他の画面も出来上がり次第随時ここを変更していく) */}
                {buttonText}
                に戻る
            </BackButton>
        </Container>
    );
};

export default ReturnPerviousButton;
