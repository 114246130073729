import React from 'react';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { ToolType } from '../../../hooks/useEditorCanvas';
import { colors } from '../../const/Styles';

type ContainerProps = {
    selected: boolean;
};
const Container = styled.i<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > span {
        display: flex;
        flex-direction: row;

        span {
            display: flex;
            flex-direction: row;
        }

        svg {
            circle {
                fill: ${({ selected }) => selected ? colors.lightGray : colors.black};
            }
            width: 38px;
            height: 38px;
        }
    }
`;

const Label = styled.label`
    display: block;
    color: ${colors.white};
    font-size: 10px;
`;

type Props = {
    icon: string;
    value: ToolType;
    selected: ToolType;
    onClick?: (value: ToolType) => void;
};

const ToolItem: React.FC<Props> = ({
    icon,
    value,
    selected,
    onClick,
    children,
}) => {
    const handleClick = () => {
        if (onClick) {
            onClick(value);
        }
    };

    return (
        <Container selected={value === selected} onClick={handleClick}>
            <ReactSVG src={icon} wrapper='span' />
            <Label>{children}</Label>
        </Container>
    );
};

export default ToolItem;
