import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import Icon from '../../../assets/movie-unselect.svg';
import { MultiplePlayMovieContext } from '../../../contexts/MultiplePlayMovieContext';
import useSelectMovieDisplayHandler from '../../../hooks/useSelectMovieDisplayHandler';
import { Routes } from '../../const/Routes';
import { colors } from '../../const/Styles';
import SelectedMovieThumbnail from '../display/SelectedMovieThumbnail';

const Container = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px 180px;
    width: 100%;
`;

const ControlContainter = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    padding-right: 38px;
`;

const ControlTitle = styled.h2`
    margin: 0;
    padding-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
    color: ${colors.white};
`;

const ControlButton = styled.button`
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: ${colors.footerIconSelected};
    color: ${colors.black};
    padding: 5px 45px;
    font-weight: bold;
    font-size: 17px;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 4px;
    margin-bottom: 15px;

    &:disabled {
        background-color: ${colors.lightGray};
    }
`;

const MovieContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 38px;

    > {
        &:nth-child(1) {
            margin-right: 70px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
`;

const IconContainer = styled.i`
    display: block;
    svg {
        width: 106px;
        color: ${colors.white};
    }
`;

const MultiplePlayControl: React.FC = () => {
    const history = useHistory();

    const handlePlay = () => {
        history.push(Routes.SWING_PLAY_DOUBLE);
    };

    const { movies } = useContext(MultiplePlayMovieContext);

    const thumbnails = useMemo(() => {
        return (
            movies.map((movie) => (
                <SelectedMovieThumbnail
                    key={movie.id}
                    video={movie}
                />
            ))
        );
    }, [movies]);

    const icons = useMemo(() => {
        return Array.from({ length: 2 - movies.length }).map(
            (_, index) => (
                <IconContainer key={index}>
                    <ReactSVG src={Icon ?? ''} />
                </IconContainer>
            ),
        );
    }, [movies]);

    const { onUnSelectAll } = useSelectMovieDisplayHandler()

    return (
        <Container>
            <ControlContainter>
                <ControlTitle>スイング選択</ControlTitle>
                <ControlButton type='button' onClick={onUnSelectAll}>
                    全選択解除
                </ControlButton>
                <ControlButton
                    type='button'
                    onClick={handlePlay}
                    disabled={movies.length !== 2}
                >
                    再生
                </ControlButton>
            </ControlContainter>
            <MovieContainer>
                {thumbnails}
                {icons}
            </MovieContainer>
        </Container>
    );
};

export default MultiplePlayControl;
