import { useCallback, useEffect, useRef, useState } from 'react';
import { useMount } from 'react-use';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';

const SKIP_SECOND = 0.05;

export interface VideoActions {
    onChangePlayBackRate: (rate: number) => void;
    skipPlus: () => void;
    skipMinus: () => void;
    skip: (second: number) => void;
    skipByRatio: (ratio: number) => void;
    togglePlay: () => Promise<void>;
    fullScreen: () => void;
}

interface VideoJS {
    // build通すための一時的処置。 https://unipro.backlog.jp/view/26_GOLF-159 で対応。
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Video: ({ children, ...props }: any) => JSX.Element;
    player: VideoJsPlayer | undefined;
    thumbnails: [string, number][] | undefined;
    actions: VideoActions;
}

export const baseOptions: VideoJsPlayerOptions = {
    height: 700,
    width: 550,
    controls: true,
    controlBar: {
        volumePanel: true,
        playToggle: false,
        remainingTimeDisplay: false,
        progressControl: true,
        playbackRateMenuButton: true,
        pictureInPictureToggle: false,
        currentTimeDisplay: true,
    },
    playbackRates: [0.0625, 0.1, 0.5, 1, 1.5],
    responsive: true,
};

const useVideoJS = (videoJsOptions: VideoJsPlayerOptions): VideoJS => {
    const videoNode = useRef<HTMLVideoElement>(null);
    const [player, setPlayer] = useState<VideoJsPlayer>();
    const [thumbnails, setThumbnails] = useState<[string, number][]>();

    useMount(() => {
        if (videoNode.current) {
            setPlayer(
                videojs(videoNode.current, videoJsOptions, function (this) {
                    // do anything
                }),
            );
            if (player) {
                return () => {
                    player.dispose();
                };
            }
        }
    });

    useEffect(() => {
        const THUMBNAIL_COUNT = 10;
        const thumbs: [string, number][] = [];
        if (videoNode.current) {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const video = document.createElement('video');
            video.crossOrigin = 'anonymous';
            videoNode.current.addEventListener(
                'canplaythrough',
                function onVideoNodeCanPlayThrough() {
                    if (videoNode.current) {
                        video.src = videoNode.current.src;
                        video.addEventListener(
                            'canplaythrough',
                            function onVideoCanPlayThrough() {
                                canvas.width = video.videoWidth;
                                canvas.height = video.videoHeight;
                                const interval =
                                    video.duration / (THUMBNAIL_COUNT - 1);
                                (function drawThumbnail() {
                                    if (thumbs.length === 0) {
                                        video.addEventListener(
                                            'timeupdate',
                                            drawThumbnail,
                                        );
                                    }
                                    if (ctx) {
                                        ctx.drawImage(
                                            video,
                                            0,
                                            0,
                                            canvas.width,
                                            canvas.height,
                                        );
                                        if (thumbs.length < THUMBNAIL_COUNT) {
                                            thumbs.push([
                                                canvas.toDataURL('image/png'),
                                                video.currentTime,
                                            ]);
                                            video.currentTime += interval;
                                        } else {
                                            video.removeEventListener(
                                                'timeupdate',
                                                drawThumbnail,
                                            );
                                            videoNode.current?.removeEventListener(
                                                'canplaythrough',
                                                onVideoNodeCanPlayThrough,
                                            );
                                            video.removeEventListener(
                                                'canplaythrough',
                                                onVideoCanPlayThrough,
                                            );
                                            setThumbnails(thumbs);
                                        }
                                    }
                                })();
                            },
                        );
                    }
                },
            );
        }
    }, [videoNode]);

    const onChangePlayBackRate = useCallback(
        (rate: number) => {
            player?.playbackRate(rate);
        },
        [player],
    );
    const skipPlus = useCallback(() => {
        player?.currentTime(player.currentTime() + SKIP_SECOND);
    }, [player]);
    const skipMinus = useCallback(() => {
        player?.currentTime(player.currentTime() - SKIP_SECOND);
    }, [player]);
    const fullScreen = useCallback(() => {
        player?.requestFullscreen();
    }, [player]);
    const skip = useCallback(
        (second: number) => {
            player?.currentTime(second);
        },
        [player],
    );
    const skipByRatio = useCallback((ratio: number) => {
        // 指定可能なのは0～100までとする
        if (!player || ratio > 100 || ratio < 0) {
            return;
        }

        player.currentTime(player.duration() * (ratio / 100));
    }, [player],);
    const togglePlay = useCallback(async () => {
        if (player?.paused() === true) {
            await player.play();
            return;
        }
        if (player?.paused() === false) {
            player.pause();
            return Promise.resolve();
        }
    }, [player]);
    const actions: VideoActions = {
        onChangePlayBackRate,
        skipPlus,
        skipMinus,
        skip,
        skipByRatio,
        togglePlay,
        fullScreen,
    };

    const Video = useCallback(({ children, ...props }) => {
        return (
            <div data-vjs-player=''>
                <video
                    ref={videoNode}
                    className='video-js vjs-big-play-centered'
                    {...props}
                >
                    {children}
                </video>
            </div>
        );
    }, []);
    return { Video, player, thumbnails, actions };
};

export default useVideoJS;
