import React, { useEffect, useRef, useState } from 'react';
import { useAsyncFn } from 'react-use';
import styled from 'styled-components';
import { Movie } from '../../../domains/resource/movie';
import useEditorCanvas, { LineColor, ToolType } from '../../../hooks/useEditorCanvas';
import { VideoActions } from '../../../hooks/useVideoJs';
import RecordingStore from '../../../store/RecordingStore';
import Api from '../../../utils/Api';
import base64ToFile from '../../../utils/base64ToFile';
import MovieEditorToolHorizontal from '../../swing/display/MovieEditorToolHorizontal';
import { VideoBlob, VideoMovie } from '../PlayingVideoCard';
import PlayController from '../VideoComposer/PlayControler';
import Editor, { ForwardProps } from './Editor';
import Footer from './Footer';

const Container = styled.section`
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    width: 100%;
    height: 100%;
`;

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    width: 100%;
    flex-wrap: nowrap;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const HEIGHT = 475;
const WIDTH = 328;

type Props = {
    resources: [VideoMovie | VideoBlob, Movie.Resource][]
}

const MultiVideoComposer: React.FC<Props> = ({ resources }) => {

    const [canvasValuesLeft, canvasActionsLeft] = useEditorCanvas(
        resources[0][0],
    );

    const [canvasValuesRight, canvasActionsRight] = useEditorCanvas(
        resources[1][0],
    );

    const [toolType, setToolType] = React.useState<ToolType>(
        ToolType.straightLine,
    );

    const handleToolType = (newToolType: ToolType) => {
        setToolType(newToolType);
        canvasActionsLeft.setToolType(newToolType);
        canvasActionsRight.setToolType(newToolType);
    };

    const [lineColor, setLineColor] = React.useState<LineColor>(LineColor.red);
    const handleLineColor = (newLineColor: LineColor) => {
        setLineColor(newLineColor);
        canvasActionsLeft.setLineColor(newLineColor);
        canvasActionsRight.setLineColor(newLineColor);
    };

    const editor1 = useRef<ForwardProps>(null);
    const editor2 = useRef<ForwardProps>(null);

    const [isPlaying, setIsPlaying] = useState(false);

    const [, dispacher] = useAsyncFn(async () => {
        const commands = [editor1, editor2].map(({ current }) => {
            if (current) {
                return current.togglePlay();
            }
            return Promise.resolve();
        });
        await Promise.all(commands);
        setIsPlaying(!isPlaying);
    }, [isPlaying, setIsPlaying]);

    const handleStart = () => {
        void dispacher();
    };

    const [, saveDispacher] = useAsyncFn(async () => {
        const editors = [editor1, editor2];
        const converts = editors.map(({ current }) => {
            if (current) {
                return current.convertToImg();
            }
            return Promise.resolve();
        });
        // 編集内容の画像変換
        await Promise.all(converts);
        // それぞれ変換した画像をバックエンドに投げる
        const canvasValues = [canvasValuesLeft, canvasValuesRight];
        const ajax = resources.map(async ([src,], index) => {
            const base64 = canvasValues[index].canvasRef.current
                ?.toDataURL('image/png')
                .split(',')[1];
            if (base64) {
                const imgFile = base64ToFile(base64, 'test');
                if (imgFile) {
                    if (src.type === 'movie') {
                        const result = await Api.updateToolImage(
                            src.id,
                            src.customerIdOrStaffId,
                            src.movieCreatedAt,
                            src.filename,
                        );
                        const presignedUrl =
                            await Api.getS3SignedUrlForUploadToolImage(
                                result.tool_image_filename,
                            );
                        await Api.uploadToS3(
                            presignedUrl.presignedUrl,
                            imgFile,
                        );
                    } else {
                        RecordingStore.saveToolImage(src, imgFile);
                    }
                }
            }
        });
        await Promise.allSettled(ajax);
        window.alert('編集内容を保存しました');
    }, []);

    const handleOnSave = () => {
        void saveDispacher();
    };

    const handleVideoAction: VideoActions = {
        onChangePlayBackRate: (rate: number) => {
            [editor1, editor2].map(({ current }) => {
                if (current) {
                    current.onChangePlayBackRate(rate);
                }
            });
        },
        skipPlus: () => {
            [editor1, editor2].map(({ current }) => {
                if (current) {
                    current.skipPlus();
                }
            });
        },
        skipMinus: () => {
            [editor1, editor2].map(({ current }) => {
                if (current) {
                    current.skipMinus();
                }
            });
        },
        skip: (second: number) => {
            [editor1, editor2].map(({ current }) => {
                if (current) {
                    current.skip(second);
                }
            });
        },
        skipByRatio: (ratio: number) => {
            [editor1, editor2].map(({ current }) => {
                if (current) {
                    current.skipByRatio(ratio);
                }
            });
        },
        togglePlay: async () => {
            const commands = [editor1, editor2].map(({ current }) => {
                if (current) {
                    return current.togglePlay();
                }
                return Promise.resolve();
            });
            await Promise.all(commands);
        },
        fullScreen: () => {
            // 同時再生時はフルスクリーン不可とする（そもそも複数動画同時にフルスクリーンにさせるのは無理
            return;
        }
    };

    const [currentPlayingTime, setCurrentPlayingTime] = useState(0);
    const [duration, setDuration] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPlayingTime(Math.max(editor1.current?.player?.currentTime() || 0, editor2.current?.player?.currentTime() || 0));
            setDuration(Math.max(editor1.current?.player?.duration() || 0, editor2.current?.player?.duration() || 0));
        }, 100);
        return () => {
            clearInterval(intervalId);
        };
    }, [setCurrentPlayingTime]);

    const handleChangeCurrentPlayingTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        [editor1, editor2].forEach((editorRef) => {
            editorRef.current?.skipByRatio(Number(event.target.value) * 5);
        })
    }

    return (
        <Container>
            <MovieEditorToolHorizontal
                selected={toolType}
                selectedColor={lineColor}
                onClick={handleToolType}
                onLineColorChange={handleLineColor}
            />
            <ContentContainer>
                <VideoContainer>
                    <Editor
                        videoSrc={resources[0][0]}
                        resource={resources[0][1]}
                        canvasValues={canvasValuesLeft}
                        canvasActions={canvasActionsLeft}
                        height={HEIGHT}
                        width={WIDTH}
                        ref={editor1}
                    />
                    <Editor
                        videoSrc={resources[1][0]}
                        resource={resources[1][1]}
                        canvasValues={canvasValuesRight}
                        canvasActions={canvasActionsRight}
                        height={HEIGHT}
                        width={WIDTH}
                        ref={editor2}
                    />
                </VideoContainer>
                <PlayController
                    actions={handleVideoAction}
                    onStart={handleStart}
                    isPlaying={isPlaying}
                    useFullScreen={false}
                    seekbarAction={{
                        duration,
                        onChangeSlider: handleChangeCurrentPlayingTime,
                    }}
                />
            </ContentContainer>
            <Footer onClickSave={handleOnSave} />
        </Container>
    )
};

export default MultiVideoComposer;
