import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PlayArrowSharp from '@material-ui/icons/PlayArrowSharp';
import StopIcon from '@material-ui/icons/Stop';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import styled from 'styled-components';
import { VideoActions } from '../../../hooks/useVideoJs';
import { colors } from '../../const/Styles';
import PlaybackRateSelector from './PlaybackRateSelector';
import StyledSlider from "../input/StyledSlider";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 12px;
    width: 100%;
`;

const StyledIconButton = styled(IconButton)`
    padding: 0;
    svg {
        height: 50px;
        width: 50px;
    }
`;

const PlayControlContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    left: -35px;
`
const OptionControlContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    left: 30px;
`;

type SeekbarProps = {
    duration?: number
    onChangeSlider?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

type Props = {
    isBackgroundDark?: boolean;
    isPlaying: boolean;
    useFullScreen: boolean;
    onStart: () => void;
    actions: VideoActions;
    seekbarAction: SeekbarProps;
};

const PlayController: React.FC<Props> = ({
    isBackgroundDark,
    actions,
    seekbarAction,
    isPlaying,
    useFullScreen,
    onStart,
}) => {
    return (
        <Container>
            <PlayControlContainer>
                <StyledIconButton onClick={actions.skipMinus}>
                    <ChevronLeftIcon
                        fontSize='large'
                        htmlColor={colors.footerIconSelected}
                    />
                </StyledIconButton>
                <StyledIconButton onClick={onStart}>
                    {isPlaying ? (
                        <StopIcon
                            fontSize='large'
                            htmlColor={colors.footerIconSelected}
                        />
                    ) : (
                        <PlayArrowSharp
                            fontSize='large'
                            htmlColor={colors.footerIconSelected}
                        />
                    )}
                </StyledIconButton>
                <StyledIconButton onClick={actions.skipPlus}>
                    <ChevronRightIcon
                        fontSize='large'
                        htmlColor={colors.footerIconSelected}
                    />
                </StyledIconButton>
            </PlayControlContainer>
            {seekbarAction.onChangeSlider &&
                <StyledSlider
                    defaultValue={2}
                    onChange={seekbarAction.onChangeSlider}
                />
            }
            <PlaybackRateSelector
                isBackgroundDark={isBackgroundDark}
                onChange={actions.onChangePlayBackRate}
            />
            {useFullScreen && (
                <OptionControlContainer>
                    <StyledIconButton onClick={actions.fullScreen}>
                        <FullscreenIcon
                            fontSize='large'
                            htmlColor={colors.footerIconSelected}
                        />
                    </StyledIconButton>
                </OptionControlContainer>
            )}
        </Container>
    );
};

export default PlayController;
