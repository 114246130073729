import {
    Box,
    Button,
    createStyles,
    makeStyles,
    Slider,
    Theme,
} from '@material-ui/core';
import {
    forwardRef,
    RefObject,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';

import TrushIcon from '../../assets/trush.svg';
import useEditorAngle from '../../hooks/useEditorAngle';
import {
    Actions as CanvasActions,
    Values as CanvasValues,
} from '../../hooks/useEditorCanvas';
import useEditorCircle from '../../hooks/useEditorCircle';
import useEditorGuidStamp from '../../hooks/useEditorGuidStamp';
import useEditorLine from '../../hooks/useEditorLine';
import useEditorRectangle from '../../hooks/useEditorRectangle';
import useVideoJS, { VideoActions } from '../../hooks/useVideoJs';
import { colors } from '../const/Styles';
import ActionToolItem from '../swing/surface/ActionToolItem';
import { VideoBlob, VideoMovie } from './PlayingVideoCard';
import AngleSVG from './SVG/AngleSVG';
import CircleSVG from './SVG/CircleSVG';
import ForwardTiltGuidSVG from './SVG/ForwardTiltGuidSVG';
import FrontGuidSVG from './SVG/FrontGuidSVG';
import LineSVG from './SVG/LineSVG';
import RectangleSVG from './SVG/RectangleSVG';
import SwingPlaneSVG from './SVG/SwingPlaneSVG';

const StyledToolItem = styled(ActionToolItem)`
    align-items: flex-end;
    svg {
        color: ${colors.footerIconSelected};
    }
`;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'absolute',
            width: '90%',
            height: '90%',
            border: '4px solid #333333',
            boxShadow: theme.shadows[5],
            overflow: 'scroll',
        },
        videoContainer: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            backgroundColor: theme.palette.grey[600],
        },
        video: {
            position: 'absolute',
            '& > .vjs-tech': {
                height: '475px',
                width: '328px',
                objectFit: 'fill',
            },
        },
        canvas: {
            border: '1px solid gray',
            backgroundColor: 'white',
            position: 'absolute',
        },
        svg: {
            position: 'absolute',
        },
        thumbnailBox: (props: { width: number }) => ({
            display: 'flex',
            justifyContent: 'space-between',
            width: props.width,
        }),
        thumbnail: {
            width: '100%',
            height: 'auto',
        },
        imgButton: {
            padding: 0,
            minWidth: 0,
        },
    }),
);

export interface ForwardProps extends VideoActions {
    convertToImg: () => Promise<void>;
}
interface Props {
    videoSrc: VideoMovie | VideoBlob;
    canvasValues: CanvasValues;
    canvasActions: CanvasActions;
    height: number;
    width: number;
}

const VideoEditorSide = forwardRef<ForwardProps, Props>((props, ref) => {
    const { videoSrc, canvasValues, canvasActions, height, width } = props;
    const classes = useStyles({ width });
    const src = useMemo(
        () =>
            videoSrc.type === 'movie' ? videoSrc.filepath : videoSrc.videoSrc,
        [videoSrc],
    );
    const { Video, player, actions, thumbnails } = useVideoJS({
        sources: [{ src: src, type: 'video/mp4' }],
        height: height,
        width: width,
        responsive: true,
        muted: true,
    });

    const [lineValues, lineActions] = useEditorLine();
    const [angleValues, angleActions] = useEditorAngle();
    const [planeValues, planeActions] = useEditorAngle();
    const [rectValues, rectActions] = useEditorRectangle();
    const [circleValues, circleActions] = useEditorCircle();
    const [frontStampValues, frontStampActions] = useEditorGuidStamp();
    const [forwardStampValues, forwardStampActions] = useEditorGuidStamp();

    const onClickDeleteAll = useCallback(() => {
        const canvas = canvasValues.canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
            }
        }
        lineActions.reset();
        angleActions.reset();
        planeActions.reset();
        rectActions.reset();
        circleActions.reset();
        frontStampActions.reset();
        forwardStampActions.reset();
    }, [
        angleActions,
        canvasValues.canvasRef,
        circleActions,
        forwardStampActions,
        frontStampActions,
        lineActions,
        planeActions,
        rectActions,
    ]);

    const svgLineRef = useRef<SVGSVGElement>(null);
    const svgAngleRef = useRef<SVGSVGElement>(null);
    const svgPlaneRef = useRef<SVGSVGElement>(null);
    const svgRectangleRef = useRef<SVGSVGElement>(null);
    const svgCircleRef = useRef<SVGSVGElement>(null);
    const svgFrontStampRef = useRef<SVGSVGElement>(null);
    const svgForwardStampRef = useRef<SVGSVGElement>(null);

    const [currentPlayingTime, setCurrentPlayingTime] = useState(0);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentPlayingTime(player?.currentTime() ?? 0);
        }, 10);
        return () => {
            clearInterval(intervalId);
        };
    }, [player, setCurrentPlayingTime]);

    const onSeekbarChange = useCallback(
        (second) => {
            actions.skip(second);
        },
        [actions],
    );

    const saveToImage = async (
        ref: RefObject<SVGSVGElement>,
        ctx: CanvasRenderingContext2D,
    ) => {
        const { current } = ref;
        if (current) {
            return new Promise<void>((resolve) => {
                const img = new Image();
                const svgData = new XMLSerializer().serializeToString(current);
                img.src =
                    'data:image/svg+xml;charset=utf-8;base64,' + btoa(svgData);
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    resolve();
                };
            });
        }
        return Promise.resolve();
    };

    const handleSave = useCallback(async () => {
        const canvas = canvasValues.canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            if (ctx) {
                // 画像変換
                await saveToImage(svgLineRef, ctx);
                await saveToImage(svgAngleRef, ctx);
                await saveToImage(svgPlaneRef, ctx);
                await saveToImage(svgRectangleRef, ctx);
                await saveToImage(svgCircleRef, ctx);
                await saveToImage(svgFrontStampRef, ctx);
                await saveToImage(svgForwardStampRef, ctx);
                // 操作のリセット
                lineActions.reset();
                angleActions.reset();
                planeActions.reset();
                rectActions.reset();
                circleActions.reset();
                frontStampActions.reset();
                forwardStampActions.reset();
            }
        }
        return Promise.resolve();
    }, [
        angleActions,
        canvasValues.canvasRef,
        circleActions,
        forwardStampActions,
        frontStampActions,
        lineActions,
        planeActions,
        rectActions,
    ]);

    useImperativeHandle<ForwardProps, ForwardProps>(ref, () => ({
        ...actions,
        convertToImg: handleSave,
    }));

    return (
        <Box>
            <Box className={classes.videoContainer}>
                <Video playsInline className={classes.video} />
                <canvas
                    style={{ zIndex: 1, touchAction: 'none' }}
                    ref={canvasValues.canvasRef}
                    id='canvas'
                    height={`${height}px`}
                    width={`${width}px`}
                    onPointerDown={(e) =>
                        canvasActions.startDrawing(
                            e.nativeEvent.offsetX,
                            e.nativeEvent.offsetY,
                        )
                    }
                    onPointerUp={(e) =>
                        canvasActions.endDrawing(
                            e.nativeEvent.offsetX,
                            e.nativeEvent.offsetY,
                        )
                    }
                    onPointerLeave={() => canvasActions.endDrawing()}
                    onPointerMove={(e) =>
                        canvasActions.draw(
                            e.nativeEvent.offsetX,
                            e.nativeEvent.offsetY,
                        )
                    }
                />
                <LineSVG
                    svgLineRef={svgLineRef}
                    canvasValues={canvasValues}
                    lineValues={lineValues}
                    lineActions={lineActions}
                    height={height}
                    width={width}
                />
                <AngleSVG
                    svgAngleRef={svgAngleRef}
                    canvasValues={canvasValues}
                    angleValues={angleValues}
                    angleActions={angleActions}
                    height={height}
                    width={width}
                />
                <SwingPlaneSVG
                    svgPlaneRef={svgPlaneRef}
                    canvasValues={canvasValues}
                    planeValues={planeValues}
                    planeActions={planeActions}
                    height={height}
                    width={width}
                />
                <RectangleSVG
                    svgRectangleRef={svgRectangleRef}
                    canvasValues={canvasValues}
                    rectValues={rectValues}
                    rectActions={rectActions}
                    height={height}
                    width={width}
                />
                <CircleSVG
                    svgCircleRef={svgCircleRef}
                    canvasValues={canvasValues}
                    circleValues={circleValues}
                    circleActions={circleActions}
                    height={height}
                    width={width}
                />
                <FrontGuidSVG
                    svgStampRef={svgFrontStampRef}
                    canvasValues={canvasValues}
                    stampValues={frontStampValues}
                    stampActions={frontStampActions}
                    height={height}
                    width={width}
                />
                <ForwardTiltGuidSVG
                    svgStampRef={svgForwardStampRef}
                    canvasValues={canvasValues}
                    stampValues={forwardStampValues}
                    stampActions={forwardStampActions}
                    height={height}
                    width={width}
                />
            </Box>
            <Box>
                <Box className={classes.thumbnailBox}>
                    {thumbnails?.map(([thumbnail, currentTime]) => {
                        return (
                            <Button
                                key={currentTime}
                                onClick={() => {
                                    actions.skip(currentTime);
                                    setCurrentPlayingTime(currentTime);
                                }}
                                className={classes.imgButton}
                            >
                                <img
                                    src={thumbnail}
                                    className={classes.thumbnail}
                                />
                            </Button>
                        );
                    })}
                </Box>
                <Box style={{ width: '90%', margin: '0 auto' }}>
                    <Slider
                        defaultValue={0}
                        min={0}
                        max={player?.duration()}
                        value={currentPlayingTime}
                        onChange={onSeekbarChange}
                        style={{ color: colors.footerIconSelected }}
                    />
                </Box>
                <Box>
                    <StyledToolItem
                        icon={TrushIcon ?? ''}
                        onClick={onClickDeleteAll}
                    >
                        編集を削除
                    </StyledToolItem>
                </Box>
            </Box>
        </Box>
    );
});

export default VideoEditorSide;
