import React from 'react';
import styled from 'styled-components';
import GuideOnIcon from '../../../assets/humanguide_on.png';
import GuideOffIcon from '../../../assets/humanguide_off.png';

type Props = {
    isVisible: boolean;
    onClick?: () => void;
    className?: string;
};

const Icon = styled.img`
    cursor: pointer;
    width: 100px;
    height: auto;
`;

const Wrapper = styled.i`
    display: flex;
    flex-direction: column;
    padding: 0 12px;
`;

const GuideImageControlButton: React.FC<Props> = ({
    isVisible,
    onClick,
    className,
}) => {
    return (
        <Wrapper onClick={onClick} className={className}>
            {isVisible ? (
                <Icon src={GuideOnIcon} />
            ) : (
                <Icon src={GuideOffIcon} />
            )}
        </Wrapper>
    );
};

export default GuideImageControlButton;
