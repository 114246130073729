import { observer } from 'mobx-react-lite';
import React from 'react';
import styled from 'styled-components';
import HeaderIcon from '../../assets/swing.svg';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import TitleView from './display/TitleView';
import ReturnPerviousButton from './input/ReturnPerviousButton';
import SelectSortBox from './input/SelectSortBox';
import VideoType from './surface/VideoType';


import { useLocation } from 'react-router';

import CoachMovies from './surface/CoachMovies';
import CustomerMovies from './surface/CustomerMovies';
import FavoritedMovies from './surface/FavoritedMovies';

const Container = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: ${colors.white};
`;

interface LocationState {
    from: string;
    customerId: string;
}

const SwingListPage: React.FC = () => {
    const location = useLocation<LocationState>();
    const { customerId } = location.state;

    return (
        <Page headerIcon={HeaderIcon} title='スイング一覧'>
            <Container>
                <TitleView />
                <VideoType />
                <SelectSortBox />
                <CustomerMovies customerId={Number(customerId)} />
                <CoachMovies />
                <FavoritedMovies customerId={`${customerId}`} />
                <ReturnPerviousButton />
            </Container>
        </Page>
    );
};

export default observer(SwingListPage);
