import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import VisibleIcon from '../../../assets/eye-opened.svg';
import TrushIcon from '../../../assets/trush.svg';
import { colors } from '../../const/Styles';
import ActionToolItem from '../surface/ActionToolItem';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: 100%;
    padding-top: 12px;
    z-index: 5;
`;

const StyledActionToolItem = styled(ActionToolItem)`
    svg {
        width: 40px;
        height: 40px;
    }
`

const VisibilityButton = styled(StyledActionToolItem)`
    fill: ${(props) => (props['aria-checked'] ? colors.white : 'transparent')};
`;


type Props = {
    onClickDeleteAll?: () => void;
    onClickVisible?: (visibility: boolean) => void;
};

const MovieEditorToolLeft: React.FC<Props> = ({
    onClickDeleteAll,
    onClickVisible,
}) => {
    const [visible, setVisible] = useState(true);
    const handleClickVisible = useCallback(() => {
        const next = !visible;
        setVisible(next);
        if (onClickVisible) {
            onClickVisible(next);
        }
    }, [visible, setVisible, onClickVisible]);

    const visibilityText = useMemo(() => {
        return visible ? 'ツールを隠す' : 'ツールを表示';
    }, [visible]);

    return (
        <Container>
            <VisibilityButton
                icon={VisibleIcon ?? ''}
                onClick={handleClickVisible}
                aria-checked={visible}
            >
                {visibilityText}
            </VisibilityButton>
            {visible && (
                <StyledActionToolItem
                    icon={TrushIcon ?? ''}
                    onClick={onClickDeleteAll}
                >
                    編集を削除
                </StyledActionToolItem>
            )}
        </Container>
    );
};

export default MovieEditorToolLeft;
