import React, { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import styled from 'styled-components';

import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import HeaderIcon from '../../assets/note.svg';
import { useStore } from '../../contexts/StoreContext';
import { SavedToolImageMap } from '../../store/RecordingStore';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import RecordControlButton from './display/RecordControlButton';
import SaveSwingModal from './display/SaveSwingModal';
import RecordVideoThumbnail from './surface/RecordVideoThumbnail';
import LocalMovieSelectButton from '../base/input/LocalMovieSelectButton';
import GuideImageBack from '../../assets/address_dr_back.png';
import FrontImageBack from '../../assets/address_dr_front.png';
import GuideDirectionControlButton from './input/GuideDirectionControlButton';
import GuideImageControlButton from './input/GuideImageControlButton';

const Container = styled.section`
    display: grid;
    grid-template-rows: auto auto 1fr auto;
    width: 100%;
    height: 100%;
`;

const Header = styled.h3`
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    color: ${colors.footerIconSelected};
    margin: 0;
    padding: 0;
    border-bottom: solid 2px ${colors.lightGray};
`;

const RecordedVideoList = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    height: 150px;
    padding: 10px;
    border-bottom: solid 2px ${colors.lightGray};
    box-sizing: border-box;
    background-color: black;
    > video {
        margin-right: 10px;
    }
`;

const StreamBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-sizing: border-box;
    max-width: 100vw;
    overflow: hidden;
    border-bottom: solid 2px ${colors.lightGray};
`;

const StreamContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    box-sizing: border-box;
    height: fit-content;
    max-height: 100%;
    width: fit-content;
    max-width: 100%;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
`;

const FooterButton = styled(Button)`
    &.MuiButtonBase-root {
        width: 60%;
        color: ${colors.black};
        font-weight: bold;
        background-color: ${colors.footerIconSelected};
    }
`;

const StyledControlButton = styled(RecordControlButton)`
    position: absolute;
    bottom: 5px;
    margin-left: 50%;
    margin-right: 50%;
`;

const StyledLocalMovieSelectButton = styled(LocalMovieSelectButton)`
    position: absolute;
    right: 100%;
    margin-top: 20px;
` ;

const GuideControlContainer = styled.div`
    position: absolute;
    left: 100%;
    margin-top: 20px;
`;

const BackDirectionGuideImage = styled.img`
    position: absolute;
    width: auto;
    height: 52%;
    left: 40%;
    bottom: 100px;
    opacity: 0.8;
`;

const FrontDirectionGuideImage = styled.img`
    position: absolute;
    width: auto;
    height: 58%;
    left: 40%;
    bottom: 65px;
    opacity: 0.8;
`;

type GuideLineProps = {
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
};

const HorizontalGuideLine = styled.p<GuideLineProps>`
    margin: 0;
    padding: 0;
    width: 100%;
    height: 1px;
    background-color: ${colors.red};
    opacity: 0.7;
    position: absolute;
    ${({ top, bottom, left, right }) => {
        const position = {};
        if (typeof left === 'number') {
            Object.assign(position, { left: `${left}px` });
        }
        if (typeof right === 'number') {
            Object.assign(position, { right: `${right}px` });
        }
        if (typeof top === 'number') {
            Object.assign(position, { top: `${top}px` });
        }
        if (typeof bottom === 'number') {
            Object.assign(position, { bottom: `${bottom}px` });
        }
        return position;
    }}
`;

const VerticalGuideLine = styled(HorizontalGuideLine)`
    width: 1px;
    height: 100%;
`;

const RecordSwingPage: React.FC = () => {
    const { recordingStore } = useStore();
    const { customerToolImageMap } = recordingStore;
    const videoConstraints: MediaTrackConstraints = {
        facingMode: { exact: 'environment' },
    };
    const webcamRef = useRef<Webcam>(null);

    const [recording, setRecording] = useState(false);
    const recordingCountRef = useRef(0);

    const [isVisibleGuideImageControl, setIsVisibleGuideImageControl] =
        useState(true);
    const [isBackDirection, setIsBackDirection] = useState(true);

    const handleRecord = useCallback(() => {
        if (recording) {
            recordingStore.handleStopCaptureClick();
            recordingCountRef.current += 1;
        } else {
            recordingStore.handleStartCaptureClick(webcamRef);
        }
        setRecording(!recording);
    }, [recordingStore, recording, setRecording]);

    const history = useHistory();
    const handleGoBack = () => {
        history.goBack();
    };

    const [selectedToolMap, setSelectedToolMap] = useState<SavedToolImageMap>();

    const handleOpenSaveModal = (toolmap: SavedToolImageMap) => {
        setSelectedToolMap(toolmap);
    };
    const handleCloseSaveModal = () => {
        setSelectedToolMap(undefined);
    };
    const handleClickGuideImageControl = () => {
        setIsVisibleGuideImageControl(!isVisibleGuideImageControl);
    };
    const handleClickGuideDirectionControl = () => {
        // 人物ガイドOFFの状態の場合は操作を受け付けないように
        if (!isVisibleGuideImageControl) {
            return;
        }

        setIsBackDirection(!isBackDirection);
    };

    const handleAfterAdd = useCallback(
        (current: SavedToolImageMap[]) => {
            const registereds =
                recordingStore.getRegisteredVideo(
                    recordingStore.viewedCustomerId,
                ) ?? [];
            if (recordingCountRef.current > 0 || registereds.length > 0) {
                handleOpenSaveModal(current[current.length - 1]);
            }
        },
        [recordingStore],
    );

    const handleChangeLocalMovie = useCallback((file: Blob) => {
        recordingCountRef.current += 1;
        recordingStore.addLocalMovieToViewedCustomer(file);
    }, [recordingStore])

    useEffect(() => {
        recordingStore.afterAddHook = handleAfterAdd;
        return () => {
            recordingStore.afterAddHook = undefined;
        };
    }, [handleAfterAdd, recordingStore]);

    return (
        <Page title='レッスンノート' headerIcon={HeaderIcon ?? ''}>
            <Container>
                <Header>スイング撮影</Header>
                <RecordedVideoList>
                    {customerToolImageMap.map((toolMap) => (
                        <RecordVideoThumbnail
                            key={toolMap.id}
                            data={toolMap}
                            onClick={handleOpenSaveModal}
                        />
                    ))}
                </RecordedVideoList>
                <StreamBody>
                    <StreamContainer>
                        <StyledLocalMovieSelectButton onChange={handleChangeLocalMovie} />
                        <GuideControlContainer>
                            <GuideImageControlButton
                                onClick={handleClickGuideImageControl}
                                isVisible={isVisibleGuideImageControl}
                            ></GuideImageControlButton>
                            <GuideDirectionControlButton
                                onClick={handleClickGuideDirectionControl}
                                isBackDirection={isBackDirection}
                                isVisibleGuideImage={isVisibleGuideImageControl}
                            ></GuideDirectionControlButton>
                        </GuideControlContainer>
                        <Webcam
                            ref={webcamRef}
                            width='auto'
                            height='100%'
                            audio={false}
                            videoConstraints={videoConstraints}
                        ></Webcam>
                        {isVisibleGuideImageControl && isBackDirection && (
                            <BackDirectionGuideImage src={GuideImageBack} />
                        )}
                        {isVisibleGuideImageControl && !isBackDirection && (
                            <FrontDirectionGuideImage src={FrontImageBack} />
                        )}
                        <HorizontalGuideLine top={180} left={0} />
                        <HorizontalGuideLine bottom={95} left={0} />
                        <VerticalGuideLine left={96} />
                        <VerticalGuideLine right={96} />
                        <StyledControlButton
                            recording={recording}
                            onClick={handleRecord}
                        />
                    </StreamContainer>
                </StreamBody>
                <Footer>
                    <FooterButton onClick={handleGoBack}>
                        本日のレッスンに戻る
                    </FooterButton>
                </Footer>
            </Container>
            <SaveSwingModal
                toolmap={selectedToolMap}
                onClose={handleCloseSaveModal}
            />
        </Page>
    );
};

export default RecordSwingPage;
