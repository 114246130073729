import React from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { colors } from '../../const/Styles';

import { useHistory } from 'react-router-dom';
import BackIcon from '../../../assets/goback.svg';
import SyncroPlayIcon from '../../../assets/synchroplay.png';
import { Routes } from '../../const/Routes';

const Container = styled.section`
    display: flex;
    flex-redirection: row;
    width: 100%;
    border-top: 3px solid ${colors.lightGray};
    padding: 15px 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: ${colors.black};
    position: relative;
`;

const Title = styled.h2`
    margin: 0;
    line-height: 1;
    font-size: 24px;
    font-weight: bold;
    color: ${colors.footerIconSelected};
    text-align: center;
`;

const IconRight = styled.i`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
    height: 100%;
    padding-right: 70px;
    color: ${colors.footerIconSelected};
    font-size: 14px;
    font-style: normal;
    svg {
        width: 30px;
        height: 30px;
    }
`;

const IconLeft = styled(IconRight)`
    top: 0;
    left: 0;
    padding-left: 70px;
    padding-right: 0;
`;

const Image = styled.img`
    object-fit: scale-down;
    height: 50px;
`

type Props = {
    customerId: string;
};
const PlayerHeaderNavigation: React.FC<Props> = ({ customerId, children }) => {
    const history = useHistory();
    const handleClickSwingPlayDouble = () => {
        history.push(Routes.SWING_LIST, {
            customerId: customerId,
            from: Routes.LESSON_PAST_SWING,
        });
    };
    const handleClickBackIcon = () => {
        history.goBack();
    };

    return (
        <Container>
            <IconLeft onClick={handleClickBackIcon}>
                <ReactSVG src={BackIcon ?? ''} />
                本日のレッスンに戻る
            </IconLeft>
            <Title>{children}</Title>
            <IconRight onClick={handleClickSwingPlayDouble}>
                <Image src={SyncroPlayIcon} />
            </IconRight>
        </Container>
    );
};

export default PlayerHeaderNavigation;
