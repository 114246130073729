import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import HeaderIcon from '../../assets/note.svg';
import { MultiplePlayMovieContext } from '../../contexts/MultiplePlayMovieContext';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import MultiVideoComposer from '../video/MultiVideoComposer';
import PlayerHeaderNavigation from './navigation/PlayerHeaderNavigation';
import { Movie } from '../../domains/resource/movie';
import { VideoBlob, VideoMovie } from '../video/PlayingVideoCard';

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: ${colors.gray};
`;

const PlayDoublePage: React.FC = () => {
    const { movies } = useContext(MultiplePlayMovieContext);

    const editableMovies = useMemo<[VideoMovie | VideoBlob, Movie.Resource][]>(() => {
        return movies.map(movie => [Movie.toEditable(movie), movie]);
    }, [movies]);

    const history = useHistory();
    if (movies.length < 2) {
        history.goBack();
        return null;
    }


    return (
        <Page title='レッスンノート' headerIcon={HeaderIcon ?? ''}>
            <ContentContainer>
                <PlayerHeaderNavigation>
                    スイング同時再生
                </PlayerHeaderNavigation>
                <MultiVideoComposer resources={editableMovies} />
            </ContentContainer>
        </Page>
    );
};

export default PlayDoublePage;
