import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../components/const/Routes";
import { MultiplePlayMovieContext } from "../contexts/MultiplePlayMovieContext";
import { Movie } from "../domains/resource/movie";

type WithItemCallback = (video: Movie.Resource) => void;
type VoidCallback = () => void;

type Returns = {
    onSelected: WithItemCallback;
    onUnSelected: WithItemCallback;
    onUnSelectAll: VoidCallback;
}

/**
 * 同時再生時の選択処理系
 * @returns
 */
export default function useSelectMovieDisplayHandler(): Returns {
    const {
        movies,
        setMovies,
        multiplePlayMode,
    } = useContext(MultiplePlayMovieContext)

    const history = useHistory();
    const onSelected = useCallback(
        (video: Movie.Resource) => {
            // 同時再生モードの場合は再生対象に追加
            if (
                !movies.some((p) => p.type === video.type && p.id === video.id) &&
                movies.length < 2 &&
                multiplePlayMode === true
            ) {
                const nextValues = [...movies];
                nextValues.push(video);
                setMovies(nextValues);
            }

            if (multiplePlayMode === false) {
                // そうでない場合は単純に再生する
                history.push(Routes.SWING_PLAY_SINGLE, video);
            }
        },
        [multiplePlayMode, history, movies, setMovies],
    );

    const onUnSelected = useCallback((video: Movie.Resource) => {
        const nextValues = [...movies];
        const index = nextValues.findIndex(
            (p) => p.type === video.type && p.id === video.id,
        );

        const target = nextValues[index];
        if (target.type === 'blob') {
            // 排除されたらこちらも開放
            URL.revokeObjectURL(target.thumbnail);
            URL.revokeObjectURL(target.video.objectUrl);
        };

        nextValues.splice(index, 1);
        setMovies(nextValues);
        return;
    }, [movies, setMovies]);

    const onUnSelectAll = useCallback(() => {
        movies.forEach((movie) => {
            // ObjectURLを開放
            if (movie.type === 'blob') {
                URL.revokeObjectURL(movie.thumbnail);
                URL.revokeObjectURL(movie.video.objectUrl);
            }
        });
        setMovies([]);
    }, [movies, setMovies]);

    return {
        onSelected,
        onUnSelectAll,
        onUnSelected,
    }
}
