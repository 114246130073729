import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import HeaderIcon from '../../assets/note.svg';
import RecordingStore from '../../store/RecordingStore';
import Page from '../base/Page';
import { colors } from '../const/Styles';
import { VideoBlob } from '../video/PlayingVideoCard';
import VideoComposer from '../video/VideoComposer';
import PlayerHeaderNavigation from './navigation/PlayerHeaderNavigation';
import { MultiplePlayMovieContext } from '../../contexts/MultiplePlayMovieContext';
import { Movie } from '../../domains/resource/movie';

const ContentContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    background-color: ${colors.gray};
`;

const VideoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: ${colors.white};
    .userName {
        color: ${colors.white};
    }
`;

type LocationProps = {
    customerId: string;
    video: Blob;
};

const PlaySwingPage: React.FC = () => {
    const location = useLocation<LocationProps>();
    const { state } = location;
    const { customerId, video } = state;
    const [saveCounter, setSaveCounter] = useState<number>(0);

    const videos = RecordingStore.getRegisteredVideo(customerId);
    const movie = videos?.find((v) => v.video === video);

    const { setMovies, setMultiplePlayMode } = useContext(MultiplePlayMovieContext)
    const history = useHistory();
    useEffect(() => {
        if (!movie) {
            history.goBack();
        }

        if (movie && movie.video) {
            Movie.fromRawData(customerId, movie.video, movie)
                .then((data) => {
                    setMovies([data]);
                    setMultiplePlayMode(true);
                })
                .catch(error => {
                    throw error;
                });
        }
    }, [movie, customerId, history, setMovies, setMultiplePlayMode]);

    if (!movie || !movie.video) {
        return null;
    }

    const videoBlob: VideoBlob = {
        id: movie.id,
        type: 'blob',
        videoSrc: URL.createObjectURL(movie.video),
        blob: movie.video,
        toolImage: movie.toolImage ?? undefined,
    };

    const toolimage = RecordingStore.getToolImage(videoBlob);
    return (
        <Page title='レッスンノート' headerIcon={HeaderIcon ?? ''}>
            <ContentContainer>
                <PlayerHeaderNavigation customerId={customerId}>
                    スイング再生
                </PlayerHeaderNavigation>
                <VideoContainer>
                    <VideoComposer
                        ownerId={customerId}
                        movieSequence={parseInt(movie.number)}
                        club={movie.club}
                        yard={movie.yard}
                        isBestSwing={movie.isBestSwing}
                        toolImageFilePath={toolimage}
                        videoSrc={videoBlob}
                        height={601}
                        width={416}
                        saveCounter={saveCounter}
                        setSaveCounter={setSaveCounter}
                    />
                </VideoContainer>
            </ContentContainer>
        </Page>
    );
};

export default observer(PlaySwingPage);
