/* eslint-disable @typescript-eslint/no-namespace */
/**
 * TODO: 各所に型と業務ロジックが散在しているのでこんな感じで集約したい
 *  - RegisteredVideoMap, SavedToolImage, MovieDisplay結局同じことをしているため
 */

import { VideoBlob, VideoMovie } from "../../components/video/PlayingVideoCard";
import { RegisteredVideoMap } from "../../store/RecordingStore";
import { MovieDisplay } from "../../utils/Video";

type EditableData = VideoMovie | VideoBlob;

// 撮影した動画の種別(movie => 保存済み, blob: 未保存)
type RawMovieType = 'blob';
type PersistedMovieType = 'movie';
type MovieType = PersistedMovieType | RawMovieType;

type CommonData = {
    id: number
    type: MovieType;
    ownerId: string;
    club?: string | null;
    yard?: string | null;
    movieSequence?: number;
    isBestSwing: boolean;
};

export namespace Movie {
    type RawData = {
        objectUrl: string
        rawData: Blob
    }

    export type LessonResource = CommonData & {
        type: RawMovieType
        video: RawData;
        thumbnail: string;
        createdAt?: string;
        toolImage?: File
    };

    type S3KeyResource = {
        fileName: string;
        filePath: string;
    }

    type S3Resource = S3KeyResource & {
        signedUrl: string;
    };

    export type PersistedResource = CommonData & {
        type: PersistedMovieType;
        video: S3Resource;
        thumbnail: S3Resource;
        toolImage: S3KeyResource;
        createdAt?: string;
    };

    export type Resource = LessonResource | PersistedResource;

    /**
     * 動画 => サムネ変換
     * @param objectUrl
     * @returns
     */
    export const videoToThumbnail = (objectUrl: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            const player = document.createElement('video');
            player.src = objectUrl;
            player.load();
            player.addEventListener('error', () => reject('video source can not loaded'));
            player.addEventListener('canplaythrough', () => {
                player.currentTime = 1;
                player.addEventListener('timeupdate', () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = player.videoWidth;
                    canvas.height = player.videoHeight;
                    const ctx = canvas.getContext('2d');
                    if (!ctx) {
                        reject('canvas context undefined');
                        return;
                    }
                    ctx.drawImage(player, 0, 0, canvas.width, canvas.height);
                    ctx.canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(URL.createObjectURL(blob));
                        } else {
                            reject('video thumbnail convert failured');
                        }
                    }, 'image/png', '0.4');
                })
            });
        })
    };

    /**
     * 撮影時のデータ変換
     * @param customerId
     * @param video
     * @param data
     * @returns
     */
    export const fromRawData = async (customerId: string, video: Blob, data: RegisteredVideoMap): Promise<LessonResource> => {
        const videoUrl = URL.createObjectURL(video);
        const thumnailDataUrl = await videoToThumbnail(videoUrl);

        return {
            id: data.id,
            type: 'blob',
            video: {
                objectUrl: videoUrl,
                rawData: video
            },
            thumbnail: thumnailDataUrl,
            ownerId: customerId,
            club: data.club,
            yard: data.yard,
            isBestSwing: data.isBestSwing,
            toolImage: data.toolImage === null ? undefined : data.toolImage,
            movieSequence: data.number ? parseInt(data.number) : undefined,
        }
    };

    /**
     * 保存済みデータからの変換
     * @param data
     * @returns
     */
    export const fromEntity = (data: MovieDisplay): PersistedResource => {
        return {
            id: parseInt(data.id),
            type: 'movie',
            video: {
                signedUrl: data.signed_url,
                fileName: data.filename,
                filePath: data.filepath,
            },
            thumbnail: {
                signedUrl: data.thumbnail_signed_url,
                fileName: data.movie_thumbnail_filename,
                filePath: data.movie_thumbnail_filepath,
            },
            toolImage: {
                fileName: data.tool_image_filename,
                filePath: data.tool_image_filepath,
            },
            ownerId: data.customer_id_or_staff_id,
            club: data.club,
            yard: data.yards,
            isBestSwing: data.desirable,
            createdAt: data.created_at,
        }
    };

    export const toEditable = (data: Resource): EditableData => {
        if (data.type === 'blob') {
            return {
                id: data.id,
                type: 'blob',
                videoSrc: data.video.objectUrl,
                blob: data.video.rawData,
                toolImage: data.toolImage,
            }
        }

        return {
            id: data.id,
            type: 'movie',
            filepath: data.video.signedUrl,
            filename: data.video.fileName,
            customerIdOrStaffId: data.ownerId,
            movieCreatedAt: data.createdAt || '',
            toolImageFilename: data.toolImage.fileName,
            toolImageFilePath: data.toolImage.filePath,
        };
    }
}
