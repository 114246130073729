import {
    Box,
    Button,
    Card,
    Container,
    createStyles,
    makeStyles,
    Slider,
    Theme,
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, {
    ForwardedRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';

import useEditorAngle from '../../hooks/useEditorAngle';
import useEditorCanvas, {
    LineColor,
    ToolType,
} from '../../hooks/useEditorCanvas';
import useEditorCircle from '../../hooks/useEditorCircle';
import useEditorGuidStamp from '../../hooks/useEditorGuidStamp';
import useEditorLine from '../../hooks/useEditorLine';
import useEditorRectangle from '../../hooks/useEditorRectangle';
import useVideoJS from '../../hooks/useVideoJs';
import RecordingStore from '../../store/RecordingStore';
import Api from '../../utils/Api';
import base64ToFile from '../../utils/base64ToFile';
import { colors as ThemeColors } from '../const/Styles';
import MovieEditorFooter from '../swing/display/MovieEditorFooter';
import MovieEditorToolLeft from '../swing/display/MovieEditorToolLeft';
import MovieEditorToolRight from '../swing/display/MovieEditorToolRight';
import { VideoBlob, VideoMovie } from './PlayingVideoCard';
import AngleSVG from './SVG/AngleSVG';
import CircleSVG from './SVG/CircleSVG';
import ForwardTiltGuidSVG from './SVG/ForwardTiltGuidSVG';
import FrontGuidSVG from './SVG/FrontGuidSVG';
import LineSVG from './SVG/LineSVG';
import RectangleSVG from './SVG/RectangleSVG';
import SwingPlaneSVG from './SVG/SwingPlaneSVG';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            position: 'absolute',
            width: '90%',
            height: '90%',
            border: '4px solid #333333',
            boxShadow: theme.shadows[5],
            overflow: 'scroll',
            backgroundColor: '#1E1E1E',
            color: 'white',
            padding: '20px',
        },
        videoContainer: {
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            backgroundColor: '#1E1E1E',
        },
        video: {
            position: 'absolute',
            '& > .vjs-tech': {
                height: '100%',
                width: '550px',
            },
        },
        canvas: {
            border: '1px solid gray',
            backgroundColor: 'white',
            position: 'absolute',
        },
        svg: {
            position: 'absolute',
        },
        thumbnailBox: {
            display: 'flex',
            justifyContent: 'center',
            padding: '5px 0',
        },
        thumbnail: {
            width: 55,
            height: 55,
        },
        imgButton: {
            padding: 0,
            minWidth: 0,
        },
        toggleButton: {
            border: 'None',
            borderRadius: 'None',
        },
    }),
);

interface Props {
    videoSrc: VideoMovie | VideoBlob;
    saveCounter: number;
    setSaveCounter: React.Dispatch<React.SetStateAction<number>>;
    height: number;
    width: number;
    onSaveCallback?: (toolImageFileName: string) => void;
}

const VideoContainer = styled.div`
    display: flex;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
`;

const VideoEditor = observer(
    (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const {
            videoSrc,
            saveCounter,
            setSaveCounter,
            height,
            width,
            onSaveCallback,
        } = props;
        const classes = useStyles();
        const src = useMemo(
            () =>
                videoSrc.type === 'movie'
                    ? videoSrc.filepath
                    : videoSrc.videoSrc,
            [videoSrc],
        );
        const { Video, player, actions, thumbnails } = useVideoJS({
            sources: [{ src: src, type: 'video/mp4' }],
            height: height,
            width: width,
            responsive: true,
        });

        const [canvasValues, canvasActions] = useEditorCanvas(videoSrc);
        const [lineValues, lineActions] = useEditorLine();
        const [angleValues, angleActions] = useEditorAngle();
        const [planeValues, planeActions] = useEditorAngle();
        const [rectValues, rectActions] = useEditorRectangle();
        const [circleValues, circleActions] = useEditorCircle();
        const [frontStampValues, frontStampActions] = useEditorGuidStamp();
        const [forwardStampValues, forwardStampActions] = useEditorGuidStamp();

        const onClickDeleteAll = useCallback(() => {
            const canvas = canvasValues.canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                }
            }
            lineActions.reset();
            angleActions.reset();
            planeActions.reset();
            rectActions.reset();
            circleActions.reset();
            frontStampActions.reset();
            forwardStampActions.reset();
        }, [
            angleActions,
            canvasValues.canvasRef,
            circleActions,
            forwardStampActions,
            frontStampActions,
            lineActions,
            planeActions,
            rectActions,
        ]);

        const [trigger1, setTrigger1] = useState(false);
        const [trigger2, setTrigger2] = useState(false);
        const [trigger3, setTrigger3] = useState(false);
        const [trigger4, setTrigger4] = useState(false);
        const [trigger5, setTrigger5] = useState(false);
        const [trigger6, setTrigger6] = useState(false);
        const [trigger7, setTrigger7] = useState(false);
        const svgLineRef = useRef<SVGSVGElement>(null);
        const svgAngleRef = useRef<SVGSVGElement>(null);
        const svgPlaneRef = useRef<SVGSVGElement>(null);
        const svgRectangleRef = useRef<SVGSVGElement>(null);
        const svgCircleRef = useRef<SVGSVGElement>(null);
        const svgFrontStampRef = useRef<SVGSVGElement>(null);
        const svgForwardStampRef = useRef<SVGSVGElement>(null);

        const pasteSvgToCanvas = useCallback(
            (
                svgRef: React.RefObject<SVGSVGElement>,
                ctx: CanvasRenderingContext2D,
                setTriggerCallback: (bool: boolean) => void,
            ) => {
                const img = new Image();
                if (svgRef.current) {
                    const svgData = new XMLSerializer().serializeToString(
                        svgRef.current,
                    );
                    img.src =
                        'data:image/svg+xml;charset=utf-8;base64,' +
                        btoa(svgData);
                }
                img.onload = () => {
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    setTriggerCallback(true);
                };
            },
            [],
        );
        const onClickSaveAsImage = useCallback(() => {
            const canvas = canvasValues.canvasRef.current;
            if (canvas) {
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    pasteSvgToCanvas(svgLineRef, ctx, setTrigger1);
                    pasteSvgToCanvas(svgAngleRef, ctx, setTrigger2);
                    pasteSvgToCanvas(svgPlaneRef, ctx, setTrigger3);
                    pasteSvgToCanvas(svgRectangleRef, ctx, setTrigger4);
                    pasteSvgToCanvas(svgCircleRef, ctx, setTrigger5);
                    pasteSvgToCanvas(svgFrontStampRef, ctx, setTrigger6);
                    pasteSvgToCanvas(svgForwardStampRef, ctx, setTrigger7);
                }
            }
        }, [canvasValues.canvasRef, pasteSvgToCanvas]);

        useEffect(() => {
            if (
                trigger1 &&
                trigger2 &&
                trigger3 &&
                trigger4 &&
                trigger5 &&
                trigger6 &&
                trigger7
            ) {
                const base64 = canvasValues.canvasRef.current
                    ?.toDataURL('image/png')
                    .split(',')[1];
                if (base64) {
                    const imgFile = base64ToFile(base64, 'test');
                    if (imgFile) {
                        if (videoSrc.type === 'movie') {
                            void (async () => {
                                const result = await Api.updateToolImage(
                                    videoSrc.id,
                                    videoSrc.customerIdOrStaffId,
                                    videoSrc.movieCreatedAt,
                                    videoSrc.filename,
                                );
                                const presignedUrl =
                                    await Api.getS3SignedUrlForUploadToolImage(
                                        result.tool_image_filename,
                                    );
                                await Api.uploadToS3(
                                    presignedUrl.presignedUrl,
                                    imgFile,
                                );
                                if (onSaveCallback) {
                                    onSaveCallback(result.tool_image_filename);
                                }
                            })();
                            RecordingStore.saveToolImage(videoSrc, imgFile);
                            canvasActions.drawDefaultImage(videoSrc, imgFile);
                        } else {
                            RecordingStore.saveToolImage(videoSrc, imgFile);
                        }
                        setSaveCounter(saveCounter + 1);
                    }
                }
                angleActions.reset();
                planeActions.reset();
                setTrigger1(false);
                setTrigger2(false);
                setTrigger3(false);
                setTrigger4(false);
                setTrigger5(false);
                setTrigger6(false);
                setTrigger7(false);
            }
        }, [
            angleActions,
            canvasActions,
            canvasValues.canvasRef,
            planeActions,
            saveCounter,
            setSaveCounter,
            trigger1,
            trigger2,
            trigger3,
            trigger4,
            trigger5,
            trigger6,
            trigger7,
            videoSrc,
            onSaveCallback,
        ]);

        const [playing, setPlaying] = useState<boolean>(false);
        useEffect(() => {
            if (player) {
                player.on('ended', () => {
                    setPlaying(false);
                });
            }
        }, [player]);
        const startVideo = useCallback(() => {
            if (player?.paused()) {
                player?.play();
                setPlaying(true);
            } else {
                player?.pause();
                setPlaying(false);
            }
        }, [player]);

        const [currentPlayingTime, setCurrentPlayingTime] = useState(0);
        useEffect(() => {
            const intervalId = setInterval(() => {
                setCurrentPlayingTime(player?.currentTime() ?? 0);
            }, 10);
            return () => {
                clearInterval(intervalId);
            };
        }, [player, setCurrentPlayingTime]);
        const onSeekbarChange = useCallback(
            (event, second) => {
                actions.skip(second);
            },
            [actions],
        );

        const [toolType, setToolType] = React.useState<ToolType>(
            ToolType.straightLine,
        );
        const handleToolType = (newToolType: ToolType) => {
            setToolType(newToolType);
            canvasActions.setToolType(newToolType);
        };

        const [lineColor, setLineColor] = React.useState<LineColor>(
            LineColor.red,
        );
        const handleLineColor = (newLineColor: LineColor) => {
            setLineColor(newLineColor);
            canvasActions.setLineColor(newLineColor);
        };

        const [toolVisible, setToolVisible] = useState(true);
        return (
            <Card ref={ref} tabIndex={-1} className={classes.card}>
                <Container className={classes.videoContainer}>
                    <VideoContainer>
                        <Video playsInline className={classes.video} />
                        <canvas
                            style={{ zIndex: 1, touchAction: 'none' }}
                            ref={canvasValues.canvasRef}
                            id='canvas'
                            height={`${height}px`}
                            width={`${width}px`}
                            onPointerDown={(e) =>
                                canvasActions.startDrawing(
                                    e.nativeEvent.offsetX,
                                    e.nativeEvent.offsetY,
                                )
                            }
                            onPointerUp={(e) =>
                                canvasActions.endDrawing(
                                    e.nativeEvent.offsetX,
                                    e.nativeEvent.offsetY,
                                )
                            }
                            onPointerLeave={() => canvasActions.endDrawing()}
                            onPointerMove={(e) =>
                                canvasActions.draw(
                                    e.nativeEvent.offsetX,
                                    e.nativeEvent.offsetY,
                                )
                            }
                        />
                        <LineSVG
                            svgLineRef={svgLineRef}
                            canvasValues={canvasValues}
                            lineValues={lineValues}
                            lineActions={lineActions}
                            height={height}
                            width={width}
                        />
                        <AngleSVG
                            svgAngleRef={svgAngleRef}
                            canvasValues={canvasValues}
                            angleValues={angleValues}
                            angleActions={angleActions}
                            height={height}
                            width={width}
                        />
                        <SwingPlaneSVG
                            svgPlaneRef={svgPlaneRef}
                            canvasValues={canvasValues}
                            planeValues={planeValues}
                            planeActions={planeActions}
                            height={height}
                            width={width}
                        />
                        <RectangleSVG
                            svgRectangleRef={svgRectangleRef}
                            canvasValues={canvasValues}
                            rectValues={rectValues}
                            rectActions={rectActions}
                            height={height}
                            width={width}
                        />
                        <CircleSVG
                            svgCircleRef={svgCircleRef}
                            canvasValues={canvasValues}
                            circleValues={circleValues}
                            circleActions={circleActions}
                            height={height}
                            width={width}
                        />
                        <FrontGuidSVG
                            svgStampRef={svgFrontStampRef}
                            canvasValues={canvasValues}
                            stampValues={frontStampValues}
                            stampActions={frontStampActions}
                            height={height}
                            width={width}
                        />
                        <ForwardTiltGuidSVG
                            svgStampRef={svgForwardStampRef}
                            canvasValues={canvasValues}
                            stampValues={forwardStampValues}
                            stampActions={forwardStampActions}
                            height={height}
                            width={width}
                        />
                        <MovieEditorToolRight
                            toolVisible={toolVisible}
                            selected={toolType}
                            selectedColor={lineColor}
                            onClick={handleToolType}
                            onLineColorChange={handleLineColor}
                        />
                        <MovieEditorToolLeft
                            onClickDeleteAll={onClickDeleteAll}
                            onClickVisible={setToolVisible}
                        />
                    </VideoContainer>
                </Container>
                <Box>
                    <Box className={classes.thumbnailBox}>
                        {thumbnails?.map(([thumbnail, currentTime]) => {
                            return (
                                <Button
                                    key={currentTime}
                                    onClick={() => {
                                        actions.skip(currentTime);
                                        setCurrentPlayingTime(currentTime);
                                    }}
                                    className={classes.imgButton}
                                >
                                    <img
                                        src={thumbnail}
                                        className={classes.thumbnail}
                                    />
                                </Button>
                            );
                        })}
                    </Box>
                    <Box style={{ width: '80%', margin: '0 auto' }}>
                        <Slider
                            defaultValue={0}
                            min={0}
                            max={player?.duration()}
                            value={currentPlayingTime}
                            onChange={onSeekbarChange}
                            style={{ color: ThemeColors.footerIconSelected }}
                        />
                    </Box>
                    <MovieEditorFooter
                        actions={actions}
                        onSave={onClickSaveAsImage}
                        onStart={startVideo}
                        isPlaying={playing}
                    />
                </Box>
            </Card>
        );
    },
    { forwardRef: true },
);

export default VideoEditor;
