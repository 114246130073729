import React from 'react';
import styled from 'styled-components';
import BackGuideOnIcon from '../../../assets/backguide_on.png';
import BackGuideOffIcon from '../../../assets/backguide_off.png';
import FrontGuideOnIcon from '../../../assets/frontguide_on.png';
import FrontGuideOffIcon from '../../../assets/frontguide_off.png';

type Props = {
    isBackDirection: boolean;
    isVisibleGuideImage: boolean;
    onClick?: () => void;
    className?: string;
};

const Icon = styled.img`
    cursor: pointer;
    width: 100px;
    height: auto;
`;

const Wrapper = styled.i`
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    margin-top: 10px;
`;

const GuideImageControlButton: React.FC<Props> = ({
    isBackDirection,
    isVisibleGuideImage,
    onClick,
    className,
}) => {
    const getCurrentIconSource = (
        isBackDirection: boolean,
        isVisibleGuideImage: boolean,
    ) => {
        // 後方ガイド
        if (isBackDirection) {
            if (isVisibleGuideImage) {
                return BackGuideOnIcon;
            } else {
                return BackGuideOffIcon;
            }
        }

        // 前方ガイド
        if (isVisibleGuideImage) {
            return FrontGuideOnIcon;
        }

        return FrontGuideOffIcon;
    };
    const currentIconSource = getCurrentIconSource(
        isBackDirection,
        isVisibleGuideImage,
    );

    return (
        <Wrapper onClick={onClick} className={className}>
            <Icon src={currentIconSource} />
        </Wrapper>
    );
};

export default GuideImageControlButton;
