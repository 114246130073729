import { createContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { MultiPlayMovieTargetRoutes } from "../components/const/Routes"
import { Movie } from "../domains/resource/movie"

/**
 * MEMO: 同時再生は撮影直後の動画の再生には対応していない
 *  - よってここでは配列でもつことはしない
 */
type State = {
    movies: Movie.Resource[]
    setMovies: (data: Movie.Resource[]) => void
    multiplePlayMode: boolean
    setMultiplePlayMode: (data: boolean) => void
}

export const MultiplePlayMovieContext = createContext<State>({
    movies: [],
    setMovies: () => undefined,
    multiplePlayMode: false,
    setMultiplePlayMode: () => undefined,
});

export const MultiplePlayMovieContextProvider: React.FC = ({ children }) => {
    const [movies, setMovies] = useState<Movie.Resource[]>([])
    const [multiplePlayMode, setMultiplePlayMode] = useState(false)

    const location = useLocation()
    useEffect(() => {
        if (!MultiPlayMovieTargetRoutes.some(route => route === location.pathname)) {
            setMovies([]);
            setMultiplePlayMode(false);
        }
    }, [location.pathname]);

    return (
        <MultiplePlayMovieContext.Provider value={{
            movies,
            setMovies,
            multiplePlayMode,
            setMultiplePlayMode,
        }}>
            {children}
        </MultiplePlayMovieContext.Provider>
    );
};
