import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import dateFormat from 'date-fns/format';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';

import TimesIcon from '../../../assets/times.svg';
import { Movie } from '../../../domains/resource/movie';
import useSelectMovieDisplayHandler from '../../../hooks/useSelectMovieDisplayHandler';
import { colors } from '../../const/Styles';

const ThumbnailBox = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;
    box-sizing: border-box;
`;

const MovieInfo = styled.div`
    display flex;
    flex-direction: column;
    z-index: 3;
    background-color: ${colors.black}B3;
    color: ${colors.white};
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px;
    box-sizing: border-box;
    font-size: 0.7rem;
    width: 100%;
`;

const IconContainer = styled.div`
    display flex;
    flex-direction: row;
    z-index: 3;
    color: ${colors.footerIconSelected};
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 5px;
    height: 100%;
    align-items: center;
    svg {
        width: 35px;
        height: 35px;
    }
`;

const useStyles = makeStyles(() =>
    createStyles({
        title: {
            width: 40,
            height: 40,
            color: 'white',
        },
        selectedVideo: {
            outline: '5px solid blue',
            outlineOffset: '-5px',
        },
        thumbnail: {
            posistion: 'relative',
            height: 150,
            width: 110,
        },
    }),
);

interface Props {
    video: Movie.Resource;
}

const SelectedMovieThumbnail: React.FC<Props> = ({ video }) => {
    const classes = useStyles();

    const createdAt = useMemo(() => {
        return video.createdAt
            ? dateFormat(new Date(video.createdAt), 'yyyy/MM/dd')
            : '----';
    }, [video.createdAt]);

    const { onUnSelected } = useSelectMovieDisplayHandler()
    const handleClickIcon = () => {
        onUnSelected(video);
    };

    const sourceUrl = useMemo(() => {
        return typeof video.thumbnail === 'string'
            ? video.thumbnail
            : video.thumbnail.signedUrl;
    }, [video.thumbnail]);

    return (
        <ThumbnailBox>
            <img className={clsx(classes.thumbnail)} src={sourceUrl} />
            <MovieInfo>
                <div>{video.club ? video.club : '---'}</div>
                <div>{video.yard ? video.yard : '---'} y</div>
                <div>{createdAt}</div>
                <IconContainer onClick={handleClickIcon}>
                    <ReactSVG src={TimesIcon ?? ''} />
                </IconContainer>
            </MovieInfo>
        </ThumbnailBox>
    );
};

export default observer(SelectedMovieThumbnail);
