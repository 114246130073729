import { useCallback, useEffect, useState } from "react";
import { useStore } from "../contexts/StoreContext";
import Api from "../utils/Api";

/**
 * ベストスイングのトグル処理
 * @param customerId
 * @param movieId
 * @param isRecording
 * @returns
 */
export default function useCustomerBestSwingToggler(
    customerId: string,
    movieId: number,
    isRecording: boolean,
): () => Promise<boolean> {
    const { lessonStore, recordingStore } = useStore()

    const [isCustomer, setIsCustomer] = useState(false)
    useEffect(() => {
        setIsCustomer(typeof lessonStore.resolveCustomerName(Number(customerId)) !== 'undefined')
    }, [customerId, lessonStore]);

    const toggler = useCallback(async (): Promise<boolean> => {
        if (!isCustomer) {
            throw new Error('customerId not registered customer.');
        }

        if (isRecording) {
            const recordedVideo = recordingStore.getSingleRegisteredVideo(movieId);
            // 再度検索してあれば設定
            if (recordedVideo) {
                recordingStore.setBestSwingToRegisteredVideos(
                    customerId,
                    movieId,
                    !recordedVideo.isBestSwing
                );
            }

            return recordingStore.getSingleRegisteredVideo(movieId)?.isBestSwing || false;
        }

        await Api.toggleCustomerBestSwing(
            Number(customerId),
            movieId
        );

        const { isBestMovie } = await Api.fetchCustomerBestSwing(`${movieId}`);
        return isBestMovie;
    }, [isRecording, customerId, movieId, isCustomer, recordingStore]);

    return toggler;
}
