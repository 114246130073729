import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 20px;
`;

const mixinTrack = css`
    border: none;
    background: none;
    height: 100%;
    width: 100%;
`;

const mixinThumbnail = css`
    margin: .05em;
    padding: 0;
    height: 2.9em;
    width: 1em;
    border-radius: .1em;
    box-sizing: border-box;
    border: none;
    background-color: #6FC5F0;
    box-shadow:
        -13.1em 0 0 0 #313131,
        -12.05em 0 0 0 #313131,
        -11em 0 0 0 #313131,
        -9.95em 0 0 0 #313131,
        -8.9em 0 0 0 #313131,
        -7.85em 0 0 0 #2F343F,
        -6.8em 0 0 0 #2F343F,
        -5.75em 0 0 0 #283F6B,
        -4.7em 0 0 0 #283F6B,
        -3.65em 0 0 0 #214893,
        -2.6em 0 0 0 #214893,
        -1.55em 0 0 0 #1A52BC,
        -0.5em 0 0 0 #1A52BC,
        0.5em 0 0 0 #181818,
        1.55em 0 0 0 #181818,
        2.6em 0 0 0 #181818,
        3.65em 0 0 0 #181818,
        4.7em 0 0 0 #181818,
        5.75em 0 0 0 #181818,
        6.8em 0 0 0 #181818,
        7.85em 0 0 0 #181818,
        8.9em 0 0 0 #181818,
        9.95em 0 0 0 #181818,
        11em 0 0 0 #181818,
        12.05em 0 0 0 #181818,
        13.1em 0 0 0 #181818;
    cursor: ew-resize;
`;

const StyledInputRange = styled.input`
    display: block;
    margin: 0;
    padding: 0;
    font-size: inherit;
    width: 12.9em;
    height: 3em;
    border-radius: .25em;
    border: .2em solid #242424;
    background-color: #242424;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    cursor: pointer;
    transition: box-shadow .2s linear;
    box-shadow: 0 0 0 0 transparent;
    &:focus {
        box-shadow: 0 0 0 .1em  #AAAAAA;
    }
    &:hover {
        box-shadow: 0 0 0 .15em #6FC5F0;
    }
    [orient] {
        transform: rotate( attr(orient) );
    }
    [orient='90deg'] {
        transform: rotate( 90deg );
    }
    [orient='180deg'] {
        transform: rotate( 180deg );
    }
    [orient='270deg'] {
        transform: rotate( 270deg );
    }
    &[orient='90deg'], &[orient='270deg'] {
        &::-webkit-slider-thumb { cursor: ns-resize; }
        &::-moz-range-thumb { cursor: ns-resize; }
        &::-ms-thumb { cursor: ns-resize; }
        margin: 4.5em -4.5em;
        display: inline-block;
    }
    &::-webkit-slider-runnable-track {
        ${mixinTrack}
    }
    &::-moz-range-track {
        ${mixinTrack}
    }
    &::-ms-track {
        ${mixinTrack}
    }
    &::-webkit-slider-thumb {
        ${mixinThumbnail}
    }
    &::-moz-range-thumb {
        ${mixinThumbnail}
    }
    &::-ms-thumb {
        ${mixinThumbnail}
    }
    &,
    &::-webkit-slider-runnable-track,
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
    }
`;

type Props = {
    defaultValue?: number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledSlider: React.FC<Props> = ({defaultValue, onChange}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <Wrapper>
            <StyledInputRange
                type='range'
                onChange={onChange}
                min={0}
                max={19}
                step={1}
                defaultValue={defaultValue ?? 0}
                ref={inputRef}
            />
        </Wrapper>
    )
}

export default StyledSlider;
