import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import SaveSwingModal from '../../lesson/display/SaveSwingModal';
import { SavedToolImageMap } from '../../../store/RecordingStore';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 12px 150px;
    justify-conten: center;
    box-sizing: border-box;
    border-top: 2px solid ${colors.lighterGray};
    width: 100%;
    gap: 32px;
`;

const ActionButton = styled.button`
    display: flex;
    flex-direction: row;
    padding: 3px;
    outline: none;
    border: none;
    border-radius: 4px;
    color: ${colors.black};
    background-color: ${colors.footerIconSelected};
    font-size: 18px;
    font-weight: bold;
    justify-content: center;
    width: 100%;
`;

type Props = {
    toolmap?: SavedToolImageMap;
    isRecording: boolean
    onClickSave: () => void;
};

const Footer: React.FC<Props> = ({
    toolmap,
    isRecording,
    onClickSave
}) => {

    const [modalOpened, setModalOpened] = useState(false);
    const handleOpenModal = () => {
        setModalOpened(true)
    }
    const handleCloseModal = () => {
        setModalOpened(false)
    }

    const controledToolMap = useMemo(() => {
        return modalOpened ? toolmap : undefined;
    }, [toolmap, modalOpened]);

    return (
        <Container>
            <ActionButton type='button' onClick={onClickSave}>
                保存
            </ActionButton>
            {isRecording && (
                <>
                    <ActionButton type='button' onClick={handleOpenModal}>
                        スイング情報変更
                    </ActionButton>
                    <SaveSwingModal
                        onlyUpdate
                        toolmap={controledToolMap}
                        onClose={handleCloseModal}
                    />
                </>
            )}
        </Container>
    )
}

export default Footer;
