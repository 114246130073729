import React from 'react';
import styled from 'styled-components';
import { colors } from '../../const/Styles';
import { ReactSVG } from 'react-svg';

import BackIcon from '../../../assets/goback.svg';
import { useHistory } from 'react-router-dom';

const Container = styled.section`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-top: 3px solid ${colors.gray};
    padding: 15px 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: ${colors.black};
    position: relative;
`;

const Title = styled.h2`
    margin: 0;
    line-height: 1;
    font-size: 24px;
    font-weight: bold;
    color: ${colors.footerIconSelected};
    text-align: center;
`;

const Icon = styled.i`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding-left: 70px;
    color: ${colors.footerIconSelected};
    font-size: 10px;
    font-style: normal;
    svg {
        width: 30px;
        height: 30px;
    }
`;

const PlayerHeaderNavigation: React.FC = ({ children }) => {
    const history = useHistory();
    const handleClick = () => {
        history.goBack();
    };
    return (
        <Container>
            <Icon onClick={handleClick}>
                <ReactSVG src={BackIcon ?? ''} />
                スイング一覧に戻る
            </Icon>
            <Title>{children}</Title>
        </Container>
    );
};

export default PlayerHeaderNavigation;
